import * as React from 'react';

const XS = 576;
const SM = 640;
const MD = 768;
const LG = 1024;
const XL = 1280;
const XXL = 1536;

export function useViewport(defaultWidth = Infinity, defaultHeigth = Infinity) {
  const isClient = typeof window === 'object';

  const [viewport, setViewport] = React.useState({
    width: isClient ? window.innerWidth : defaultWidth,
    height: isClient ? window.innerHeight : defaultHeigth,
    pixelRatio: isClient ? window.devicePixelRatio : 1,
  });

  React.useEffect(() => {
    if (!isClient) return;

    const handleResize = () => {
      setViewport({
        width: window.innerWidth,
        height: window.innerHeight,
        pixelRatio: window.devicePixelRatio,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isClient]);

  const ctx = React.useMemo(() => {
    const isXS = viewport.width <= XS;

    const isSM = viewport.width > XS && viewport.width <= SM;
    const isSMDown = viewport.width < SM;
    const isSMUp = viewport.width >= SM;

    const isMD = viewport.width > SM && viewport.width <= MD;
    const isMDDown = viewport.width < MD;
    const isMDUp = viewport.width >= MD;

    const isLGDown = viewport.width < LG;
    const isLG = viewport.width > MD && viewport.width <= LG;
    const isLGUp = viewport.width >= LG;

    const isXLDown = viewport.width < XL;
    const isXL = viewport.width > LG && viewport.width <= XL;
    const isXLUp = viewport.width >= XL;

    const isXXL = viewport.width > XL;

    const isMob = isXS || isSM;
    return {
      ...viewport,
      isXS,
      isSM,
      isSMDown,
      isSMUp,
      isMD,
      isMDDown,
      isMDUp,
      isLG,
      isLGDown,
      isLGUp,
      isXL,
      isXLDown,
      isXLUp,
      isXXL,
      isMob,
    };
  }, [viewport]);

  return ctx;
}
