import * as React from 'react';

import { TButtonProps, Img } from '@miq/componentjs';
import { getCN, truncateStr, uuid } from '@miq/utiljs';
import { useSearchParams } from 'react-router-dom';

import { THit, THitInstance } from './utils';
import { QSelectField, QSelectFieldProps } from '@miq/formjs';

type THitOrInstance = THit | THitInstance;

export const HitWindowSearchField = ({
  options,
  ...props
}: Omit<QSelectFieldProps, 'name'> & { options: ('today' | 'yst' | 'last_7' | 'last_14' | 'last_30')[] }) => (
  <QSelectField emptyLabel="Select window" {...props} name="__window">
    {options.includes('today') && <option value="today">Today</option>}
    {options.includes('yst') && <option value="yst">Yesterday</option>}
    {options.includes('last_7') && <option value="last_7">Last 7 days</option>}
    {options.includes('last_14') && <option value="last_14">Last 14 days</option>}
    {options.includes('last_30') && <option value="last_30">Last 30 days</option>}
  </QSelectField>
);

const HitSearchButton = (props: TButtonProps & { name: string; value?: string }) => {
  const [params, setParams] = useSearchParams();
  const { name, value, children, ...rest } = props;
  return (
    <button
      {...rest}
      className={getCN(['btn-link', params.has(name) && 'bg-red-100', props?.className])}
      key={name}
      onClick={() => {
        if (params.has(name)) params.delete(name);
        else if (value) {
          params.set(name, value);
        }
        setParams(params);
      }}
    >
      {children || `${params.has(name) ? `[x] ${name}` : name} x`}
    </button>
  );
};

export const HitImg = ({ hit }: { hit: THitOrInstance }) => {
  const img = hit?.session_data?.img;
  return img ? <Img.Square src={`${img}`} className="rounded" /> : null;
};

export const HitDate = ({ hit, ...props }: { hit: THitOrInstance; className?: string; time?: string | boolean }) => {
  if (!hit.created) return null;
  return (
    <span className={props?.className} title="Date created">
      {new Date(hit?.created).format({ dateStyle: 'medium', timeStyle: 'medium' })}
    </span>
  );
};

export const HitReferrer = ({
  hit,
  search,
  ...props
}: {
  hit: THitOrInstance;
  search?: boolean;
  className?: string;
}) => {
  const { referrer } = hit;
  if (!referrer) return null;

  const title = `Referrer: ${referrer}`;

  if (search)
    return (
      <HitSearchButton name="__ref" value={referrer} className={props?.className} title={title}>
        {truncateStr(referrer)}
      </HitSearchButton>
    );
  return (
    <span className={props?.className} title={title}>
      {referrer}
    </span>
  );
};

export const HitUA = ({ hit, search, ...props }: { hit: THitOrInstance; search?: boolean; className?: string }) => {
  const { user_agent } = hit;
  if (!user_agent) return null;

  const title = `user agent: ${user_agent}`;

  if (search)
    return (
      <HitSearchButton name="__ua" value={user_agent} className={props?.className} title={title}>
        {truncateStr(user_agent!, 0, 45)}
      </HitSearchButton>
    );
  return (
    <span className={props?.className} title={title}>
      {user_agent}
    </span>
  );
};

export const HitUrl = ({
  hit,
  search,
  children,
  ...props
}: {
  hit: THitOrInstance;
  search?: boolean;
  className?: string;
  children?: React.ReactNode;
}) => {
  const { url } = hit;
  if (!url) return null;

  const title = `url: ${url}`;

  if (search)
    return (
      <HitSearchButton name="__url" value={`${url}`} className={props?.className} title={title}>
        {children || url}
      </HitSearchButton>
    );
  return (
    <span className={getCN([props?.className])} title={title}>
      {children || url}
    </span>
  );
};

export const HitPath = ({ hit, search, ...props }: { hit: THitOrInstance; search?: boolean; className?: string }) => {
  const { path } = hit;
  if (!path) return null;

  const title = `path: ${path}`;

  if (search)
    return (
      <HitSearchButton name="__path" value={`${path}`} className={props?.className} title={title}>
        {path}
      </HitSearchButton>
    );
  return (
    <span className={getCN([props?.className])} title={title}>
      {path}
    </span>
  );
};

export const HitStatus = ({ hit, search, ...props }: { hit: THitOrInstance; search?: boolean; className?: string }) => {
  const { response_status } = hit;
  if (!response_status) return null;

  const title = 'response status';
  const cn = getCN([response_status! > 299 ? 'text-danger' : 'text-success', 'bg-white', props?.className]);

  if (search)
    return (
      <HitSearchButton name="__status" value={`${response_status}`} className={cn} title={title}>
        {response_status}
      </HitSearchButton>
    );
  return (
    <span className={cn} title={title}>
      {response_status}
    </span>
  );
};

export const HitUser = ({ hit, search, ...props }: { hit: THitOrInstance; search?: boolean; className?: string }) => {
  const { ip, session_data = {}, customer_data = {} } = hit;
  let { username, _cus } = session_data || {};

  const name = customer_data?.first_name ? `${customer_data?.first_name} ${customer_data?.last_name}` : null;
  if (username) username = `@${username}`;

  const value = name || _cus || username || ip;
  const title = `user: ${value}`;

  if (search)
    return (
      <HitSearchButton name="__ip" value={ip} className={props?.className} title={title}>
        {value}
      </HitSearchButton>
    );
  return (
    <span className={props?.className} title={title}>
      {value}
    </span>
  );
};

export const HitCart = ({ hit, ...props }: { hit: THitOrInstance; className?: string }) => {
  const { _cart } = hit?.session_data || {};

  return _cart ? (
    <a href={`/staff/sales/cart/${_cart}/`} className={props?.className} target="_blank">
      view cart
    </a>
  ) : null;
};

export const HitQueries = ({ hit, ...props }: { hit: THitOrInstance; className?: string }) => {
  const { parsed_data = {} } = hit;
  const excludeKeys = ['path', 'url', 'sdt', 'atc'];

  return (
    <>
      {Object.entries(parsed_data).map((entry) => {
        const [key, val = ''] = entry;
        if (!key || !val) return null;

        if (excludeKeys.includes(key)) return null;

        return (
          <span key={uuid()} className="text-sm text-muted me-1 bg-blue-50 rounded" title={val}>
            {key}: {truncateStr(val)}
          </span>
        );
      })}
    </>
  );
};
