import * as React from 'react';

import { TCustomer } from './utils';

export const CustomerCard = ({ data }: { data: TCustomer }) => {
  if (!data || !data.slug) return null;

  const { first_name, name, last_name, email, phone } = data;

  return (
    <div className="customer-card">
      <div className="fw-bold">{name ?? `${first_name} ${last_name}`}</div>
      <div className="">{phone}</div>
      <div className="text-muted text-sm">{email}</div>
    </div>
  );
};
