import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

const SupplierOrderUpdateView = React.lazy(() => import('./views.OrderUpdate'));
const SupplierOrderProductUpdateView = React.lazy(() => import('./views.OrderProductUpdate'));
const SupplierOrderSettingUpdateView = React.lazy(() => import('./views.OrderSettingUpdate'));
const SupplierOrderListView = React.lazy(() => import('./views.List'));

export default function SupplierRoutes() {
  return (
    <Routes>
      <Route path={`:ordSlug/:prodSlug/`} element={<SupplierOrderProductUpdateView />} />
      <Route path={`:ordSlug/settings/`} element={<SupplierOrderSettingUpdateView />} />
      <Route path={`:ordSlug/`} element={<SupplierOrderUpdateView />} />
      <Route index element={<SupplierOrderListView />} />
    </Routes>
  );
}

//
//
//

export * from './models';
