import { StaffModel, StaffService } from '@miq/staffjs';
import { TSupplierOrder } from '../types';

const timeout = 10000;

class SupplierOrderService extends StaffService<TSupplierOrder> {
  markPresale(slugs: string[]) {
    return this.post(`${this._path}batch/?presale=1`, { slugs }, { timeout });
  }
  unmarkPresale(slugs: string[]) {
    return this.post(`${this._path}batch/?presale=0`, { slugs }, { timeout });
  }

  updateCategories(category: string, slugs: string[]) {
    return this.post(`${this._path}batch/`, { slugs, category }, { timeout });
  }

  postFnova(url: string) {
    return this.post(`${this._path}fnova/`, { url }, { timeout });
  }
  postPlt(url: string) {
    return this.post(`${this._path}plt/`, { url }, { timeout });
  }

  postShein(url: string) {
    return this.post(`${this._path}shein/`, { url }, { timeout });
  }

  destroyItem(prodSlug: string) {
    return this.delete(`${this._path}item/${prodSlug}/`, { timeout });
  }

  updateItem(prodSlug: string, values: Record<string, any>) {
    return this.post(`${this._path}item/${prodSlug}/`, { ...values }, { timeout });
  }
}

export const SupplierOrder = (data: TSupplierOrder = {}) =>
  StaffModel<TSupplierOrder, SupplierOrderService>(data, 'supplierorders', SupplierOrderService);

export type TSupplierOrderInstance = ReturnType<typeof SupplierOrder>;

export type SupplierType = {
  slug?: string;
  name_choices: any;
  created: any;
  updated: any;
};
