import * as React from 'react';
import * as ReactDOM from 'react-dom';

const accept: string = [
  `image/*`,
  `.pdf`,
  `.md`,
  `.doc`,
  `.docx`,
  `application/msword`,
  `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
].join(',');

export type TFile = {
  src: string;

  readonly slug?: string;
  readonly name_truncated?: string;
  readonly name?: string;
  readonly filename?: string;
  readonly ext?: string;
  readonly size?: string;
  readonly created?: string;
  readonly updated?: string;
};

export interface TFileInputProps extends React.ComponentPropsWithoutRef<'input'> {
  name?: string;
}

export const FileInput = React.forwardRef<HTMLInputElement, TFileInputProps>(({ name = 'files', ...props }, ref) => {
  return ReactDOM.createPortal(
    <input name={name} accept={accept} {...props} type="file" style={{ ...props.style, display: 'none' }} ref={ref} />,
    document.body
  );
});
