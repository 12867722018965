import * as React from 'react';

import { getCN } from '@miq/utiljs';
import { TButtonProps, Button } from '@miq/componentjs';

import Field from './Fields';
import * as Inputs from './Inputs';
import * as Comps from './components';
import { FormProvider } from './context';
import { FormProps, TFormCallbackProps } from './types';

type FormStatic = {
  Provider: typeof FormProvider;

  Error: typeof Comps.FormError;
  Label: typeof Comps.FormLabel;

  Field: typeof Field;
  Text: typeof Inputs.FormTextInput;
  TextArea: typeof Inputs.FormTextArea;
  TextAreaX: typeof Inputs.FormTextAreaX;
  Phone: typeof Inputs.FormPhoneInput;
  Checkbox: typeof Inputs.FormCheckboxInput;
  Option: typeof Inputs.Option;
  Select: typeof Inputs.FormSelectInput;

  Submit: typeof Button;
};

export type FormType = React.ForwardRefExoticComponent<FormProps> & FormStatic;

const Form = React.forwardRef<HTMLFormElement, FormProps & TFormCallbackProps>(
  ({ children, context, ...props }, ref) => {
    return (
      <form {...props} ref={ref} className={getCN(['miq-form', props.className])}>
        <FormProvider value={context}>{children}</FormProvider>
      </form>
    );
  }
) as FormType;

Form.Provider = FormProvider;

Form.Error = Comps.FormError;
Form.Label = Comps.FormLabel;

Form.Field = Field;

//#region Inputs

Form.Submit = ({ children, value, ...props }: TButtonProps) => (
  <Button {...props} type="submit">
    {children || value}
  </Button>
);

Form.Text = Inputs.FormTextInput;
Form.TextArea = Inputs.FormTextArea;
Form.TextAreaX = Inputs.FormTextAreaX;
Form.Phone = Inputs.FormPhoneInput;
Form.Checkbox = Inputs.FormCheckboxInput;
Form.Option = Inputs.Option;
Form.Select = Inputs.FormSelectInput;

export default Form;
export * from './types';
