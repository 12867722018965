import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';

import {
  Button,
  TButtonProps,
  Icons,
  TViewNavLinksProps,
  TViewSectionProps,
  View,
  ViewSection,
} from '@miq/componentjs';

import { withHasPerms } from './utils';
import { addForwardSlash, getCN, truncateStr } from '@miq/utiljs';

export const StaffNavLink = withHasPerms<NavLinkProps>(NavLink, { className: 'miq-staff-nav-link' });
export const StaffLink = withHasPerms<LinkProps>(Link, { className: 'miq-staff-link' });
export const StaffButton = withHasPerms<TButtonProps>(Button, { className: 'miq-staff-btn' });
export const StaffSection = withHasPerms<TViewSectionProps>(ViewSection);

export const StaffViewNavLinks = withHasPerms<TViewNavLinksProps>(View.NavLinks);

export type TSidebarLinkProps<T = any> = {
  href: string;
  label: string;
  icon?: ReturnType<typeof Icons.Gear>;
  end?: string | boolean;
} & T;

export const SidebarLink = ({ end, ...props }: TSidebarLinkProps) => {
  const path = location.pathname;
  const href = `/staff/${addForwardSlash(props.href)}`;

  let active = path === href || (!end && path.startsWith(href) && path.charAt(href.length - 1) === '/');

  return (
    <a href={href} className={getCN(['miq-staff-nav-link', active && 'active'])}>
      {props?.icon}
      <div className="label text-ellipsis">{truncateStr(props.label, 0, 10)}</div>
    </a>
  );
};
