import * as React from 'react';

import './table.scss';

import { getCN } from '@miq/utiljs';

export interface ITableProps<TItem> extends Omit<React.ComponentPropsWithoutRef<'table'>, 'border'> {
  border?: string | boolean;
  header?: React.ReactNode;
  items?: TItem[];
  renderItem?: (i: TItem) => React.ReactNode;
}

export default function Table<TItem>({ children, ...props }: ITableProps<TItem>) {
  const { header, items, renderItem, border } = props;
  return (
    <table className={getCN(['miq-table', border && 'border', props.className])}>
      {header && (
        <thead className="miq-table-thead">
          <tr className="miq-table-thead-tr">{header}</tr>
        </thead>
      )}

      <tbody>{children || items?.map((i) => renderItem?.(i))}</tbody>
    </table>
  );
}

const Tr: React.FC<React.ComponentPropsWithoutRef<'tr'>> = (props) => (
  <tr {...props} className={getCN(['miq-table-tr', props.className])} />
);
Table.Tr = Tr;

const Td: React.FC<React.ComponentPropsWithoutRef<'td'>> = (props) => (
  <td {...props} className={getCN(['miq-table-td', props.className])} />
);
Table.Td = Td;

const Th: React.FC<React.ComponentPropsWithoutRef<'th'>> = (props) => (
  <th {...props} className={getCN(['miq-table-th', props.className])} />
);

Table.Th = Th;
