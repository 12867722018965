import * as React from 'react';

import { getCN } from '@miq/utiljs';
import { TFormUpdateProps } from '@miq/formjs';
import { Button, ConfirmButton, Icons } from '@miq/componentjs';
import { TImgButtonAddProps, StaffImgAddButton } from '@miq/staffjs';

import { emitter } from '../events';

import { TStaffProductInstance } from './models';

type BtnUpdateProps = Omit<TFormUpdateProps<{ instance: TStaffProductInstance }>, 'context'>;

/**
 * Button component for adding images to a product
 * ```js
 * <ProductImagesAddButton productInstance={instance} onSuccess={(res: any) => onUpdate?.(res.data)} />
 * ```
 */

export const ProductImagesAddButton = (
  props: Omit<TImgButtonAddProps<{ productInstance: TStaffProductInstance }>, 'onSuccessMulti' | 'onErrorMulti'>
) => {
  const { productInstance, onSuccess, onError, ...rest } = props;
  return (
    <StaffImgAddButton
      {...rest}
      className={getCN(['btn-primary-3', props.className])}
      multiple={true}
      onSuccessMulti={(resArray) => {
        const imgsArray = resArray
          .map(({ data }: any) => data?.slug)
          .filter((slug) => !productInstance.images?.includes(slug));
        return productInstance
          .update({ images: [...productInstance.images!, ...imgsArray] })
          .then((res) => onSuccess?.(res))
          .catch((err) => onError?.(err));
      }}
    />
  );
};

/**
 * Button component for deleting a product
 * ```js
 * <ProductDeleteButton instance={instance} onSuccess={(res: any) => onUpdate?.(res.data)} />
 * ```
 */

export const ProductDeleteButton: React.FC<BtnUpdateProps & { label?: string }> = (props) => {
  const { instance, onSuccess, onError, label } = props;

  const handleDelete = (setOpen: any) => {
    instance
      .destroy()
      .then((res: any) => {
        setOpen?.(false);
        onSuccess?.(res);
        emitter.destroy({ slug: instance.slug });
      })
      .catch((err: any) => onError?.(err));
  };
  return (
    <ConfirmButton
      renderHeader={() => <div>Supprimer '{instance.name}'</div>}
      renderFooter={({ setOpen }) => (
        <div className="d-flex justify-content-between align-items-center">
          <Button onClick={() => setOpen(false)}>Annuler</Button>

          <Button onClick={() => handleDelete(setOpen)} className="btn-danger">
            Supprimer
          </Button>
        </div>
      )}
      render={() => <div className="p-3">Are you sure you want to delete this</div>}
      className={getCN(['product-delete-button btn-danger-3', props.className])}
    >
      <Icons.Trash />
      {label && <span className="label">{label}</span>}
    </ConfirmButton>
  );
};

export const ProductPinButton = ({ instance, onSuccess, ...props }: BtnUpdateProps) => {
  const { is_oos, is_published } = instance;

  const show = is_published && !is_oos;

  if (!show) return null;

  const { is_pinned } = instance;

  return (
    <Button
      className={getCN(['btn-round', is_pinned ? 'btn-primary-3' : 'btn-secondary-3'])}
      style={{ width: 20, height: 20, padding: 8 }}
      onClick={(e) => {
        e.stopPropagation();
        instance
          .update({ is_pinned: !is_pinned })
          .then((r) => {
            onSuccess?.(r);
          })
          .catch((err) => props?.onError?.(err));
      }}
      title={is_pinned ? 'Unpin this product' : 'Pin this product'}
    >
      <Icons.IsPinned is_pinned={instance.is_pinned} />
    </Button>
  );
};
