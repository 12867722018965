import * as React from 'react';

import { useSearchParams } from 'react-router-dom';
import { QueryButton } from '../Buttons';

export const QueryTabs = ({ children, name = '__tab' }: { children: React.ReactNode; name?: string }) => {
  const [params] = useSearchParams();

  return (
    <>
      {React.Children.map(children, (child) => {
        if (child?.type?.displayName !== 'Tab') {
          // used displayName instead of name because of build error
          throw new Error('Tabs children must be of type Tab');
        }

        const { value, index = undefined } = child?.props;
        if (index === true && !params.get(name)) return child;

        if (!value || params.get(name) !== value) return null;

        return child;
      })}
    </>
  );
};

// TODO: typecheck if not value require index to be true

type TTab = ({ value: string } | { index: boolean }) & { children?: React.ReactNode };

const Tab = ({ children }: TTab) => <>{children}</>;
Tab.displayName = 'Tab';

QueryTabs.Tab = Tab;
QueryTabs.Button = QueryButton;
