import * as React from 'react';

import { getCN, uuid } from '@miq/utiljs';
import { Button, TButtonProps } from '../Buttons';

function useHView<T = any>() {
  const [selection, setSelection] = React.useState<TKeyItem<T>[]>([]);
  const [isEdit, setEdit] = React.useState<boolean>(false);

  const ctx = React.useMemo(() => {
    const toggleEdit = () => {
      if (selection.length !== 0 && isEdit) setSelection([]);
      setEdit(!isEdit);
    };

    return { isEdit, setEdit, toggleEdit, selection, setSelection };
  }, [isEdit, selection]);

  return ctx;
}

const ViewCtx = React.createContext<ReturnType<typeof useHView>>(null);

export const HView = ({ context, children }: { context: ReturnType<typeof useHView>; children: React.ReactNode }) => {
  return <ViewCtx.Provider value={context}>{children}</ViewCtx.Provider>;
};

type TKeyItem<T> = { data: T; _key: string };
export type THViewListProps<T> = React.ComponentPropsWithoutRef<'div'> & {
  items: T[];
  itemCN?: string;
  renderItem: (p: { data: T }) => React.ReactNode;
};

const HViewList = <T,>({ items = [], renderItem, itemCN, ...props }: THViewListProps<T>) => {
  const { isEdit, selection, setSelection } = React.useContext(ViewCtx);
  const [list] = React.useState<TKeyItem<T>[]>(items.map((data) => ({ data, _key: uuid() })));

  return (
    <div className={getCN(['miq-hview-list', props?.className])}>
      {list.map((item) => {
        const { data, _key } = item;
        return (
          <div key={uuid()} className={getCN(['miq-hview-item', itemCN])}>
            {isEdit && (
              <input
                name={_key}
                type="checkbox"
                className="me-2"
                checked={selection.map((i) => i._key).includes(_key)}
                onChange={({ target }) => {
                  const { checked } = target;
                  if (checked) setSelection([...selection, { data, _key }]);
                  else setSelection((selection) => selection.filter((i) => i._key !== _key));
                }}
              />
            )}
            {renderItem({ data })}
          </div>
        );
      })}
    </div>
  );
};

const ViewToggleButton = (props: TButtonProps) => {
  const { toggleEdit } = React.useContext(ViewCtx);
  return <Button {...props} onClick={() => toggleEdit()} />;
};

HView.useView = useHView;
HView.Ctx = ViewCtx;
HView.List = HViewList;
HView.Toggle = ViewToggleButton;
