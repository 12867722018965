import * as React from 'react';
import { HorizontalScrollGridLayout, Icons, Loading, StickyFooterLayout } from '@miq/componentjs';
import { SharedDataCtx } from '@miq/contextjs';
import { useParams } from 'react-router-dom';

import logo from './imgs/logo.jpg';
import cover from './imgs/cover.jpg';

import { Inputs } from '@miq/formjs';
import { getCN, getImgUrl } from '@miq/utiljs';

// ?color=124&utm_campaign=likeshopme&utm_medium=socialmedia&utm_source=instagram&utm_content=www.instagram.com/p/../
// ?utm_campaign=linktreeshopig&utm_source=instabio&utm_medium=social

const SearchForm = () => {
  const { libName: utm_campaign } = useParams();
  const [q, setQ] = React.useState('');
  const { lib_data = {} } = React.useContext(SharedDataCtx);
  const { utm_content, utm_medium, utm_source } = lib_data;

  return (
    <form className={getCN(['p-search-form', 'px-1'])} action={`/shop/`}>
      <Inputs.SearchInput
        required
        value={q}
        onChange={(e) => {
          setQ(e.target.value);
        }}
        placeholder="Cherchez un produit ..."
      />
      <input type="hidden" name="utm_campaign" value={utm_campaign} />
      {utm_medium && <input type="hidden" name="utm_medium" value={utm_medium} />}
      {utm_source && <input type="hidden" name="utm_source" value={utm_source} />}
      {utm_content && <input type="hidden" name="utm_content" value={utm_content} />}
    </form>
  );
};

const Header = () => {
  const { site = {} } = React.useContext(SharedDataCtx);

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)),url(${cover})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        color: 'wheat',
        height: '150px',
        backgroundRepeat: 'no-repeat',
      }}
      className="banner"
    >
      <div className="lib-createview-header miq-container center p-0">
        <div className="py-1">
          <SearchForm />
        </div>
      </div>

      <div className="logo-wrapper">
        <img className="logo" src={logo} alt={site?.name} />
      </div>
    </div>
  );
};

export function LIBCreateView() {
  const { libName: utm_campaign } = useParams();
  const {
    isLoading,
    lib_data = {},
    site = {},
    categories = [],
    cart = {},
    recent = [],
    ...rest
  } = React.useContext(SharedDataCtx);
  const { utm_content, utm_medium, utm_source } = lib_data;

  if (isLoading) return <Loading />;

  // https://wa.me/{number}

  const data: any = { utm_campaign };
  if (utm_source) data.utm_source = utm_source;
  if (utm_medium) data.utm_medium = utm_medium;
  if (utm_content) data.utm_content = utm_content;
  const params = new URLSearchParams([...Object.entries(data)]);

  return (
    <StickyFooterLayout className="lib-createview" mainCN="lib-createview-content">
      <div>
        <Header />

        <div className="miq-container center">
          <div className="body d-flex flex-column align-items-center">
            <Social />
            <Actions {...{ params }} />
          </div>

          <Categories {...{ params }} />
        </div>
      </div>

      <div className="text-center pt-3 pb-1">
        <a href={`/jobs/ambassadors/?${params}`} className="btn btn-primary-3">
          Collabs
        </a>
        <div className="auth mt-2 text-sm">
          <span>made with</span>
          <span>
            <Icons.Heart />
          </span>
          <span>by</span>
          <span className="fw-bold">miQ</span>
        </div>
      </div>
    </StickyFooterLayout>
  );
}

const Actions = ({ params }: { params: URLSearchParams }) => {
  const { whatsapp_link, whatsapp_link_title } = React.useContext(SharedDataCtx);
  return (
    <div className="actions">
      <div className="actions-main d-flex flex-row">
        <a href={`/shop/?${params}`} className="btn btn-primary btn-md d-block fw-bold btn-main">
          Voir la boutique
        </a>

        <ExternalLink href={whatsapp_link} title={whatsapp_link_title} className="btn btn-primary btn-square">
          <Icons.Whatsapp />
        </ExternalLink>
      </div>
    </div>
  );
};

const Categories = ({ params }: { params: URLSearchParams }) => {
  const { categories = [] } = React.useContext(SharedDataCtx);
  return (
    <div className="categories">
      <HorizontalScrollGridLayout className="cat-grid">
        {categories?.map((cat: any) => {
          return (
            <div className="cat" key={cat.meta_slug}>
              <a href={`${cat.url}?${params}`} className="name" title={cat.name}>
                <img src={getImgUrl(cat?.cover?.thumb_sq)} alt={cat.name} />
                <div>{cat.name_truncated}</div>
              </a>
            </div>
          );
        })}
      </HorizontalScrollGridLayout>
    </div>
  );
};

const Social = () => {
  return (
    <div className="social">
      <ExternalLink href="https://www.facebook.com/shopfeminiti" title="shopfeminiti">
        <Icons.Facebook />
      </ExternalLink>

      <ExternalLink href="https://www.instagram.com/feminiti_/" title="feminiti_">
        <Icons.Instagram />
      </ExternalLink>

      <ExternalLink href="https://www.tiktok.com/@feminiti" title="feminiti">
        <Icons.Tiktok />
      </ExternalLink>

      <ExternalLink href="https://www.pinterest.com/feminiti" title="feminiti">
        <Icons.Pinterest />
      </ExternalLink>
    </div>
  );
};

const ExternalLink = ({ href, ...props }: any) => {
  return (
    <button
      {...props}
      onClick={(e) => {
        e.preventDefault();
        // post

        var link = document.createElement('a');
        link.href = href;
        link.target = '_blank';
        link.click();
      }}
      className={getCN(['lib-btn', props.className])}
    />
  );
};
