import * as React from 'react';
import EventEmitter from 'events';

class CrudEmitter extends EventEmitter {
  prefix?: string;

  constructor(...args: any) {
    const { prefix = '', ...rest } = args;
    super(rest);

    this.prefix = prefix;
  }

  create(payload: any) {
    this.emit(`${this.prefix}-create`, payload);
  }
  retrieve(payload: any) {
    this.emit(`${this.prefix}-retrieve`, payload);
  }
  list(payload: any) {
    this.emit(`${this.prefix}-list`, payload);
  }
  update(payload: any) {
    this.emit(`${this.prefix}-update`, payload);
  }
  destroy(payload: any) {
    this.emit(`${this.prefix}-destroy`, payload);
  }
}

export const emitter = new CrudEmitter();

type FN<T> = T & { [key: string]: any; slug: string };

type TCallBack<T = void> = {
  onList?: () => void;
  onRetrieve?: () => void;
  onCreate?: (payload: FN<T>) => void;
  onUpdate?: (payload: FN<T>) => void;
  onDestroy?: (payload: FN<T>) => void;
};

export function useCrudEmitter(callbacks: TCallBack, match?: (p: any) => boolean) {
  React.useEffect(() => {
    emitter.on('-create', (payload: any) => {
      if (match && !match(payload)) return;

      callbacks.onCreate?.(payload);
    });

    emitter.on('-update', (payload: any) => {
      if (match && !match(payload)) return;

      callbacks.onUpdate?.(payload);
    });

    emitter.on('-destroy', (payload: any) => {
      if (match && !match(payload)) return;

      callbacks.onDestroy?.(payload);
    });
  }, []);
}
