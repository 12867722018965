import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './modal.scss';

import { getCN } from '@miq/utiljs';

const widths = ['xs', 'sm', 'md', 'lg', 'xl', 'fs'];

export type ModalWidthTypes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fs';
export type TModalProps = {
  width?: ModalWidthTypes;
  children: React.ReactNode;
  header?: React.ReactNode;
  headerCN?: string;
  bodyCN?: string;
  footer?: React.ReactNode;
  footerCN?: string;
};

export default function Modal({ header, children, footer, ...props }: TModalProps) {
  let { width = 'sm', headerCN, bodyCN, footerCN, ...rest } = props;

  if (!widths.includes(width)) width = 'sm';

  return (
    <div {...rest} className={getCN(['miq-modal'])}>
      <div className="miq-modal-background" />
      <div className={getCN(['miq-modal-inner', width])}>
        <div className={getCN(['miq-modal-inner-content', width])}>
          {header && <div className={getCN(['miq-modal-inner-header', headerCN])}>{header}</div>}
          <div className={getCN(['miq-modal-inner-body', bodyCN])}>{children}</div>
          {footer && <div className={getCN(['miq-modal-inner-footer', footerCN])}>{footer}</div>}
        </div>
      </div>
    </div>
  );
}

export type PortalProps = { children: React.ReactNode };

export const Portal = ({ children }: PortalProps) => {
  return ReactDOM.createPortal(children, document.body);
};

Portal.Modal = Modal;
