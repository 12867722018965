// import * as React from 'react';

import './form.scss';

import Form from './Form';
export * from './Form';
export * from './Fields';
export * as Inputs from './Inputs';
export * from './queryFields';

// export { Inputs };

export default Form;
export { useForm } from './context';

//

export type TextChoicesType = { key: string; value: string }[];
