import isDate from 'lodash.isdate';

//#region DateMixin

/**
 * Date Object Mixin
 */

declare global {
  interface Date {
    yesterday(): Date;
    tomorrow(): Date;
    isWeekend(): boolean;
    getWeekDates(): Date[];
    getValue(): Date;
    isToday(): boolean;
    isSameDay(dt?: Date): boolean;
    isSameMonth(dt?: Date): boolean;
    subDays(count: number): Date;
    addDays(count: number): Date;
    getMonthWeeks(): Array<Date[]>;
    getWeekArray(start: Date, end: Date): Array<Date[]>;
    getMonthFirstDt(): Date;
    getMonthLastDt(): Date;
    getWeekDates(): Date[];
    getWeekFirstDt(): Date;
    getWeekLastDt(): Date;
    getDateArray(start: Date, end: Date): Date[];
    format(config?: Intl.DateTimeFormatOptions, locale?: string): string;
    formatTime(config?: Intl.DateTimeFormatOptions, locale?: string): string;
    formatDateToStr(): string;
  }
}

export const DateMixin = {
  getValue() {
    return new Date(this.valueOf());
  },
  isSameDay(dt?: Date) {
    if (!dt || !isDate(dt)) return false;
    return dt.formatDateToStr() === this.formatDateToStr();
  },
  isSameMonth(dt?: Date) {
    if (!dt) return false;
    return this.getMonth() !== dt.getMonth();
  },
  isToday() {
    return new Date().formatDateToStr() === this.formatDateToStr();
  },
  yesterday() {
    return this.subDays(1);
  },
  /**
   * @param {Number} count
   */
  subDays(count: number) {
    const currentDate = this.getValue();
    currentDate.setDate(currentDate.getDate() - count);
    return currentDate;
  },
  /**
   * @returns {Date}
   */
  tomorrow() {
    return this.addDays(1);
  },

  isWeekend() {
    return this.getDay() === 0 || this.getDay() === 6;
  },
  addDays(count: number): Date {
    const currentDate = new Date(this.valueOf());
    currentDate.setDate(currentDate.getDate() + count);
    return currentDate;
  },

  getMonthWeeks() {
    const currentDate: Date = new Date(this.valueOf());
    return currentDate.getWeekArray(currentDate.getMonthFirstDt(), currentDate.getMonthLastDt());
  },
  getWeekArray(startDate: Date, endDate: Date) {
    const startDt = startDate.getWeekFirstDt();
    const endDt = endDate.getWeekLastDt();

    let currentDate = startDt;
    const weeks = [];
    while (currentDate <= endDt) {
      const week = currentDate.getWeekDates();
      weeks.push(week);
      currentDate = currentDate.getWeekLastDt().tomorrow();
    }
    return weeks;
  },

  getMonthFirstDt() {
    const cD = new Date(this.valueOf());
    return new Date(cD.getFullYear(), cD.getMonth(), 1);
  },
  getMonthLastDt() {
    const cD = new Date(this.valueOf());
    return new Date(cD.getFullYear(), cD.getMonth() + 1, 0);
  },

  getWeekDates() {
    const currentDate: Date = new Date(this.valueOf());
    return currentDate.getDateArray(currentDate.getWeekFirstDt(), currentDate.getWeekLastDt());
  },
  getWeekFirstDt() {
    const currentDate = new Date(this.valueOf());
    const firstDt = new Date(currentDate);
    const offset = currentDate.getDate() - currentDate.getDay();
    firstDt.setDate(offset);
    return firstDt;
  },
  getWeekLastDt() {
    const currentDate = new Date(this.valueOf());
    const firstDt = currentDate.getWeekFirstDt();
    const offset = firstDt.getDate() + 6;
    firstDt.setDate(offset);
    return firstDt;
  },
  getDateArray(startDate: Date, endDate: Date): Date[] {
    let currentDate = startDate;
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = currentDate.tomorrow();
    }
    return dates;
  },

  formatTime(config?: Intl.DateTimeFormatOptions, locale?: string) {
    if (!config) config = { hour: 'numeric', minute: 'numeric' };
    return this.format(config, locale);
  },

  /** Formats a date */
  format(config?: Intl.DateTimeFormatOptions, locale?: string): string {
    if (!config) config = { weekday: 'short', month: 'long', day: 'numeric' };
    if (!locale) locale = new Intl.NumberFormat().resolvedOptions().locale || 'en-US';

    const date_time = new Intl.DateTimeFormat(locale, { ...config });
    return date_time.format(this);
  },

  formatDateToStr(): string {
    var dd = this.getDate();
    var mm = this.getMonth() + 1; //January is 0!
    var yyyy = this.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
  },
};

Object.assign(Date.prototype, DateMixin);

//#endregion DateMixin

// export const formatDate = (
//   date: Date | string,
//   format = ,
//   locale =
// ) => {
//   if (!date || (!isString(date) && !isDate(date))) return date;

// };

// export const formatDateToStr = (date?: Date | string | null) => {
//   if (!isDate(date)) return '';

//   var dd = date.getDate();
//   var mm = date.getMonth() + 1; //January is 0!
//   var yyyy = date.getFullYear();

//   if (dd < 10) {
//     dd = '0' + dd;
//   }
//   if (mm < 10) {
//     mm = '0' + mm;
//   }

//   return yyyy + '-' + mm + '-' + dd;
// };

// export const formatTime = (
//   date: Date,
//   format = { hour: 'numeric', minute: 'numeric' },
//   locale = new Intl.NumberFormat().resolvedOptions().locale
// ) => {
//   const date_time = new Intl.DateTimeFormat(locale, { ...format });
//   return date_time.format(new Date(date));
// };
