import React from 'react';

import './pagination.scss';

import { getCN } from '@miq/utiljs';
import { ArrowLeft, ArrowRight } from '../Icons';
import { LinkProps, NavLinkProps } from 'react-router-dom';

// source: https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => i + start);
};

const DOTS: string = '...';

type PaginatedProps = { is_paginated: boolean };
type PrevProps = PaginatedProps & { has_previous: boolean; has_previous_page_number: number; has_previous_url: string };
type NextProps = PaginatedProps & { has_next: boolean; has_next_page_number: number; has_next_url: string };
export type TPageProps = PaginatedProps &
  PrevProps &
  NextProps & {
    count: number;
    num_pages: number;
    page_obj_number: number;
    per_page: number;
    allow_empty_first_page: boolean;
    siblingCount?: number;
    is_drf?: boolean;
  };

export type TPagination = TPageProps;

export type TDRFPaginated<T = any> = { results: T[] } & TPageProps;

export interface IPaginationProps extends Omit<TDRFPaginated, 'results'> {
  className?: string;
}

export const Pagination: React.FC<IPaginationProps & { to?: string | boolean; component?: any }> = (props) => {
  if (!props.is_paginated) return null;

  const { num_pages, page_obj_number } = props;

  const linksRange = () => {
    const siblingCount = props.siblingCount || 1;
    const totalPageNumbers = siblingCount + 5;
    if (totalPageNumbers >= num_pages) return range(1, num_pages);

    const leftLinkIdx = Math.max(page_obj_number - siblingCount, 1);
    const rightLinkIdx = Math.min(page_obj_number + siblingCount, num_pages);
    const hasLDots = leftLinkIdx > 2;
    const hasRDots = rightLinkIdx < num_pages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = num_pages;

    if (!hasLDots && hasRDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, num_pages];
    }

    if (hasLDots && !hasRDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(num_pages - rightItemCount + 1, num_pages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (hasLDots && hasRDots) {
      let middleRange = range(leftLinkIdx, rightLinkIdx);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
  };

  const { is_drf } = props;

  if (is_drf) {
    if (typeof window === 'undefined') return null;

    const path = new URL(window.location.href);
    const { has_previous, has_previous_page_number, has_next } = props;

    if (has_previous) {
      if (Number(has_previous_page_number) < 2) {
        path.searchParams.delete('page');
      } else path.searchParams.set('page', `${has_previous_page_number}`);

      props = { ...props, has_previous_url: `${path.pathname}${path.search}` };
    }

    if (has_next) {
      path.searchParams.set('page', `${props.has_next_page_number}`);
      props = { ...props, has_next_url: `${path.pathname}${path.search}` };
    }
  }

  return (
    <div className="miq-pagination-links d-flex align-items-center">
      <Prev {...props} />

      {linksRange().map((item, i) => {
        if (item === DOTS)
          return (
            <div className="item empty" key={`${item}${i}`}>
              {item}
            </div>
          );

        const path = new URL(location.href);
        if (item === 1) path.searchParams.delete('page');
        else path.searchParams.set('page', `${item}`);

        return (
          <div className={getCN(['item', item === page_obj_number && 'current'])} key={`${item}${i}`}>
            <Anchor
              to={props.to}
              component={props.component}
              href={`${path.pathname}${path.search}`}
              className="item-link"
            >
              {item}
            </Anchor>
          </div>
        );
      })}

      <Next {...props} />
    </div>
  );
};

interface AnchorProps extends React.ComponentPropsWithoutRef<'a'> {
  to?: string | boolean;
  label?: string;
  component?: React.FC<LinkProps | NavLinkProps>;
  // target?: '_blank' | '_parent' | '_self' | '_top';
}

const Prev: React.FC<AnchorProps & PrevProps & {}> = (props) => {
  const { is_paginated, has_previous, has_previous_url, ...rest } = props;
  if (!is_paginated || !has_previous) return null;

  return (
    <div className="item">
      <Anchor {...rest} href={has_previous_url} className="miq-pagination-prev">
        <ArrowLeft className="icon" />
      </Anchor>
    </div>
  );
};

const Next: React.FC<AnchorProps & NextProps & {}> = (props) => {
  const { is_paginated, has_next, has_next_url, ...rest } = props;
  if (!is_paginated || !has_next) return null;
  return (
    <div className="item">
      <Anchor {...rest} href={has_next_url} className="miq-pagination-next">
        <ArrowRight className="icon" />
      </Anchor>
    </div>
  );
};

const Anchor: React.FC<AnchorProps> = ({ component = 'a', to, href, ...props }) => {
  if (to && component === 'a') throw new Error('Link/NavLink required');

  const { children, label, allow_empty_first_page, has_previous, has_next, ...rest } = props;
  if (to) {
    const Component = component;
    return <Component {...{ children, className: props.className }} to={`${href}`} />;
  }

  return (
    <a {...rest} href={href}>
      {children || label}
    </a>
  );
};
