import * as React from 'react';

import { getCN } from '@miq/utiljs';

import { TIconProps } from './types';

const withTablerIcon = (children: React.ReactNode) => {
  // https://tabler-icons.io/

  return React.forwardRef<SVGSVGElement, TIconProps>((props, ref) => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={getCN(['miq-icon', props.className])}
        ref={ref}
      >
        {children}
      </svg>
    );
  });
};

export const ShoppingCart = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="6" cy="19" r="2"></circle>
    <circle cx="17" cy="19" r="2"></circle>
    <path d="M17 17h-11v-14h-2"></path>
    <path d="M6 5l14 1l-1 7h-13"></path>
  </>
);
export const ShoppingCartPlus = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="6" cy="19" r="2"></circle>
    <circle cx="17" cy="19" r="2"></circle>
    <path d="M17 17h-11v-14h-2"></path>
    <path d="M6 5l6.005 .429m7.138 6.573l-.143 .998h-13"></path>
    <path d="M15 6h6m-3 -3v6"></path>
  </>
);

export const Home = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
  </>
);

export const HomeDollar = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M19 10l-7 -7l-9 9h2v7a2 2 0 0 0 2 2h6"></path>
    <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.387 0 .748 .11 1.054 .3"></path>
    <path d="M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
    <path d="M19 21v1m0 -8v1"></path>
  </>
);

export const Coin = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1"></path>
    <path d="M12 6v2m0 8v2"></path>
  </>
);
export const BrandAppGallery = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="4" y="4" width="16" height="16" rx="4"></rect>
    <path d="M9 8a3 3 0 0 0 6 0"></path>
  </>
);
export const Affiliate = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275"></path>
    <path d="M11.683 12.317l5.759 -5.759"></path>
    <circle cx="5.5" cy="5.5" r="1.5"></circle>
    <circle cx="18.5" cy="5.5" r="1.5"></circle>
    <circle cx="18.5" cy="18.5" r="1.5"></circle>
    <circle cx="8.5" cy="15.5" r="4.5"></circle>
  </>
);

export const ChartBar = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="3" y="12" width="6" height="8" rx="1"></rect>
    <rect x="9" y="8" width="6" height="12" rx="1"></rect>
    <rect x="15" y="4" width="6" height="16" rx="1"></rect>
    <line x1="4" y1="20" x2="18" y2="20"></line>
  </>
);

export const Shirt = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M15 4l6 2v5h-3v8a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-8h-3v-5l6 -2a3 3 0 0 0 6 0"></path>
  </>
);

export const Settings = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
    <circle cx="12" cy="12" r="3"></circle>
  </>
);

export const Adjustments = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="6" cy="10" r="2"></circle>
    <line x1="6" y1="4" x2="6" y2="8"></line>
    <line x1="6" y1="12" x2="6" y2="20"></line>
    <circle cx="12" cy="16" r="2"></circle>
    <line x1="12" y1="4" x2="12" y2="14"></line>
    <line x1="12" y1="18" x2="12" y2="20"></line>
    <circle cx="18" cy="7" r="2"></circle>
    <line x1="18" y1="4" x2="18" y2="5"></line>
    <line x1="18" y1="9" x2="18" y2="20"></line>
  </>
);

export const UserCircle = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <circle cx="12" cy="10" r="3"></circle>
    <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>
  </>
);

export const Menu2 = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1={4} y1={6} x2={20} y2={6}></line>
    <line x1={4} y1={12} x2={20} y2={12}></line>
    <line x1={4} y1={18} x2={20} y2={18}></line>
  </>
);

export const Edit = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
    <path d="M16 5l3 3"></path>
  </>
);
export const Pin = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M15 4.5l-4 4l-4 1.5l-1.5 1.5l7 7l1.5 -1.5l1.5 -4l4 -4"></path>
    <line x1={9} y1={15} x2="4.5" y2="19.5"></line>
    <line x1="14.5" y1={4} x2={20} y2="9.5"></line>
  </>
);
export const Pinned = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M9 4v6l-2 4v2h10v-2l-2 -4v-6"></path>
    <line x1={12} y1={16} x2={12} y2={21}></line>
    <line x1={8} y1={4} x2={16} y2={4}></line>
  </>
);

export const IsPinned = ({ is_pinned, ...props }: TIconProps & { is_pinned?: boolean }) =>
  is_pinned ? <Pinned {...props} /> : <Pin {...props} />;

export const Share = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx={6} cy={12} r={3}></circle>
    <circle cx={18} cy={6} r={3}></circle>
    <circle cx={18} cy={18} r={3}></circle>
    <line x1="8.7" y1="10.7" x2="15.3" y2="7.3"></line>
    <line x1="8.7" y1="13.3" x2="15.3" y2="16.7"></line>
  </>
);

export const Facebook = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
  </>
);
export const Instagram = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x={4} y={4} width={16} height={16} rx={4}></rect>
    <circle cx={12} cy={12} r={3}></circle>
    <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
  </>
);
export const Tiktok = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5"></path>
  </>
);
export const Pinterest = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1={8} y1={20} x2={12} y2={11}></line>
    <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
    <circle cx={12} cy={12} r={9}></circle>
  </>
);
export const Heart = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
  </>
);

export const Star = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
  </>
);

export const Receipt = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
  </>
);
export const PigMoney = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M15 11v.01"></path>
    <path d="M5.173 8.378a3 3 0 1 1 4.656 -1.377"></path>
    <path d="M16 4v3.803a6.019 6.019 0 0 1 2.658 3.197h1.341a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-1.342c-.336 .95 -.907 1.8 -1.658 2.473v2.027a1.5 1.5 0 0 1 -3 0v-.583a6.04 6.04 0 0 1 -1 .083h-4a6.04 6.04 0 0 1 -1 -.083v.583a1.5 1.5 0 0 1 -3 0v-2l.001 -.027a6 6 0 0 1 3.999 -10.473h2.5l4.5 -3h.001z"></path>
  </>
);

export const AddressBook = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z"></path>
    <path d="M10 16h6"></path>
    <circle cx={13} cy={11} r={2}></circle>
    <path d="M4 8h3"></path>
    <path d="M4 12h3"></path>
    <path d="M4 16h3"></path>{' '}
  </>
);

export const Notification = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10 6h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
    <circle cx={17} cy={7} r={3}></circle>
  </>
);

export const Bell = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
  </>
);

export const ArrowUp = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1={12} y1={5} x2={12} y2={19}></line>
    <line x1={18} y1={11} x2={12} y2={5}></line>
    <line x1={6} y1={11} x2={12} y2={5}></line>
  </>
);

export const ArrowDown = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1={12} y1={5} x2={12} y2={19}></line>
    <line x1={18} y1={13} x2={12} y2={19}></line>
    <line x1={6} y1={13} x2={12} y2={19}></line>
  </>
);

export const ArrowsSort = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M3 9l4 -4l4 4m-4 -4v14"></path>
    <path d="M21 15l-4 4l-4 -4m4 4v-14"></path>
  </>
);

export const CloudUpload = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
    <polyline points="9 15 12 12 15 15"></polyline>
    <line x1={12} y1={12} x2={12} y2={21}></line>
  </>
);

export const DragDrop = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M19 11v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
    <path d="M13 13l9 3l-4 2l-2 4l-3 -9"></path>
    <line x1={3} y1={3} x2={3} y2="3.01"></line>
    <line x1={7} y1={3} x2={7} y2="3.01"></line>
    <line x1={11} y1={3} x2={11} y2="3.01"></line>
    <line x1={15} y1={3} x2={15} y2="3.01"></line>
    <line x1={3} y1={7} x2={3} y2="7.01"></line>
    <line x1={3} y1={11} x2={3} y2="11.01"></line>
    <line x1={3} y1={15} x2={3} y2="15.01"></line>
  </>
);

export const TruckOff = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx={7} cy={17} r={2}></circle>
    <path d="M15.585 15.586a2 2 0 0 0 2.826 2.831"></path>
    <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h1m3.96 0h4.04v4m0 4v4m-4 0h6m6 0v-6h-6m-2 -5h5l3 5"></path>
    <line x1={3} y1={3} x2={21} y2={21}></line>
  </>
);

export const Truck = withTablerIcon(
  <>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx={7} cy={17} r={2}></circle>
    <circle cx={17} cy={17} r={2}></circle>
    <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"></path>
  </>
);
