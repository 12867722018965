import * as React from 'react';

import type { TProduct } from '@shopy/storejs';

import Staff from '@miq/staffjs';
import { getCN } from '@miq/utiljs';
import { useDocumentTitle } from '@miq/hookjs';
import { SharedDataCtx } from '@miq/contextjs';
import { Icons, Loading } from '@miq/componentjs';

import { ProductImage } from '../cart/components';
import { CustomerUpdateLink } from '../customer/links';

export default function SalesIndexView() {
  useDocumentTitle('Sales');

  const ctx = React.useContext(SharedDataCtx);
  const { total, supplier_total, orders_count, carts_count, placed_count, customers_count, prospects_count } = ctx;
  const { top_cart, top_selling = [], top_customers = [], total_by_month = {} } = ctx;

  if (ctx.status === 'fetching') return <Loading />;

  if (!total) return null;

  return (
    <Staff.View className="sales-index-view miq-container">
      {/* <pre>{JSON.stringify(ctx, undefined, 4)}</pre> */}

      <Staff.Section mainCN="d-grid grid-2 grid-md-4 gap-1">
        <CardLink
          title={
            <div className="d-flex align-items-top">
              <div className="me-1">Ventes</div>
              {supplier_total && <div className="text-sm bg-orange-100">- {supplier_total.amountWithSymbol}</div>}
            </div>
          }
        >
          <div className="d-flex align-items-top">
            <div className="text-md">{total.amountWithSymbol}</div>
          </div>
        </CardLink>

        <CardLink title="Commandes" icon={<Icons.Receipt />}>
          <div className="d-flex align-items-center">
            <div className="text-md">{orders_count}</div>
          </div>
        </CardLink>

        <CardLink title="Paniers" link={{ to: './carts/', text: 'Voir les paniers' }} icon={<Icons.BrandAppGallery />}>
          <div className="d-flex align-items-center">
            <div className="text-md me-1">{carts_count}</div>
            <div className="text-muted text-sm">/{placed_count}</div>
          </div>
        </CardLink>

        <CardLink title="Clients" icon={<Icons.AddressBook />}>
          <div className="d-flex align-items-center">
            <div className="text-md me-1">{customers_count}</div>
            <div className="text-muted text-sm">/{prospects_count}</div>
          </div>
        </CardLink>
      </Staff.Section>

      {total_by_month && (
        <Staff.Section title="Performance par mois">
          <ul>
            {Object.values(total_by_month).map((item: { month: string; total: number; count: number }) => {
              return (
                <li key={item.month}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="me-1">{new Date(item.month).toLocaleString('fr-FR', { month: 'long' })}</div>
                    <div className="text-sm text-muted">
                      {item.count} commandes ({item.total} cfa)
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Staff.Section>
      )}

      {top_cart?.length > 0 && (
        <Staff.Section title="Dans le plus de paniers">
          <div className="d-grid grid-5 grid-md-10 gap-1">
            {top_cart.map((product: TProduct) => {
              return (
                <div key={product.slug}>
                  <a href={product?.url} title={product.name} target="_blank" rel="noopener noreferrer">
                    <ProductImage product={product} />
                  </a>
                </div>
              );
            })}
          </div>
        </Staff.Section>
      )}

      {top_selling?.length > 0 && (
        <Staff.Section title="Meilleures ventes">
          <div className="d-grid grid-5 grid-md-10 gap-1">
            {top_selling.map((product: TProduct) => {
              return (
                <div key={product.slug}>
                  <a href={product?.url} title={product.name} target="_blank" rel="noopener noreferrer">
                    <ProductImage product={product} />
                  </a>
                </div>
              );
            })}
          </div>
        </Staff.Section>
      )}

      {top_customers?.length > 0 && (
        <Staff.Section title="Meilleurs clients">
          <ol className="d-grid grid-md-2 gap-1">
            {top_customers.map((cus: { name: string; slug: string; orders_count: number }) => {
              return (
                <li key={cus.slug} className="ms-2">
                  <CustomerUpdateLink data={cus}>
                    <span title="Orders count" className="text-sm text-muted">
                      ({cus.orders_count})
                    </span>{' '}
                    {cus.name}
                  </CustomerUpdateLink>
                </li>
              );
            })}
          </ol>
        </Staff.Section>
      )}
    </Staff.View>
  );
}

const CardLink = ({
  children,
  title,
  link,
  ...props
}: {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  tab?: string;
  link?: { to: string; text: string; perms?: string[] };
  children?: React.ReactNode;
}) => {
  return (
    <div className="card-link">
      <div className="rounded border- p-1 mb-1">
        <div className="d-flex justify-content-between align-items-center">
          {/* {link && (
            <Staff.Link to={link.to} requiredPerms={link.perms} className="d-block btn-primary-3">
              {` » `}
            </Staff.Link>
          )} */}
        </div>
        {children}

        {title && (
          <div className="d-flex">
            {props?.icon}
            <div className={getCN(['card-link-title', props?.icon && 'ms-1'])}>{title}</div>
          </div>
        )}
      </div>
    </div>
  );
};
