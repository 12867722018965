import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import Form, { QSelectField, QSelectFieldProps, QToggleField, QToggleFieldProps } from '@miq/formjs';

import { CategoryChoicesType } from '../../types';

import { i18n } from './index';

//

export function ProductListFilterForm({
  children,
  ...props
}: {
  fields: ('category' | 'size' | 'published' | 'presale' | 'sale' | 'nodesc' | 'nosize' | 'oos')[];
  children?: React.ReactNode;
  categories?: CategoryChoicesType;
  sizes?: string[];
  onSubmit?: (p: { e: React.FormEvent<HTMLFormElement>; params: URLSearchParams }) => void;
}) {
  const [params, setParams] = useSearchParams();
  const [q, setQ] = React.useState<string>(params.get('q') || '');
  const { categories, sizes, fields, ...rest } = props;

  return (
    <form
      {...rest}
      action="."
      method="GET"
      onSubmit={(e) => {
        const { onSubmit } = props;

        e.preventDefault();

        if (!q) params.delete('q');
        else params.set('q', q);
        params.delete('page');

        // if (onSubmit) return onSubmit({ e, params });

        setParams(params);
        if (onSubmit) onSubmit({ e, params });
      }}
    >
      {children || (
        <>
          <div className="mb-2 d-flex">
            <input
              type="text"
              name="q"
              value={q}
              onChange={(e) => {
                const { value } = e.target;
                setQ(value);
                if (!value && params.get('q')) params.delete('q');
              }}
              className="miq-form-input flex-1"
              placeholder={i18n('ListFilterForm.Q.placeholder')}
            />
            <Form.Submit value={i18n('ListFilterForm.submitLabel')} className="btn-primary-3 ms-1" />
          </div>

          <div className="d-grid grid-md-4 grid-lg-6 gap-2">
            <div>
              {fields.includes('category') && categories && (
                <div className="mb-1">
                  <CategorySearchField {...{ params, setParams, categories }} />
                </div>
              )}
              {fields.includes('size') && sizes && <SizeSearchField {...{ params, setParams, sizes }} />}
            </div>
            {fields.includes('published') && (
              <div>
                <PublishedSearchField {...{ params, setParams }} className="mb-1" />
              </div>
            )}

            <div>
              {fields.includes('sale') && <SaleSearchField className="mb-1" />}
              {fields.includes('presale') && <PreSaleSearchField className="mb-1" />}
            </div>

            <div>
              {fields.includes('nodesc') && <NoDescriptionSearchField className="mb-1" />}
              {fields.includes('oos') && <OosSearchField className="mb-1" />}
            </div>
          </div>
        </>
      )}
    </form>
  );
}

const CategorySearchField = ({
  categories,
  ...props
}: Omit<QSelectFieldProps, 'name'> & { categories: CategoryChoicesType }) => (
  <QSelectField emptyLabel={i18n('ListFilterForm.categoryEmptyLabel')} {...props} name="cat">
    <option value="no-cat">{i18n('ListFilterForm.noCategoryOptionLabel')}</option>

    {categories.items.map((cat) => {
      return (
        <option value={cat.value} key={cat.slug}>
          {cat.label}
        </option>
      );
    })}
  </QSelectField>
);

const SizeSearchField = ({ sizes, ...props }: Omit<QSelectFieldProps, 'name'> & { sizes: string[] }) => (
  <QSelectField emptyLabel={'All sizes'} {...props} name="size">
    <option value="nosize">No size</option>

    {sizes.map((size) => {
      return (
        <option value={size} key={size}>
          {size}
        </option>
      );
    })}
  </QSelectField>
);

const PublishedSearchField = (props: Omit<QSelectFieldProps, 'name'>) => {
  return (
    <QSelectField emptyLabel={i18n('ListFilterForm.publishedEmptyLabel')} {...props} name="published">
      <option value="include">{i18n('ListFilterForm.publishedOptionLabel')}</option>
      <option value="pinned"> {i18n('ListFilterForm.pinnedOptionLabel')}</option>
      <option value="exclude"> {i18n('ListFilterForm.unpublishedOptionLabel')}</option>
      <option value="explicit"> {i18n('ListFilterForm.explicitOptionLabel')}</option>
    </QSelectField>
  );
};

const PreSaleSearchField = (props: Omit<QToggleFieldProps, 'name' | 'label'>) => {
  return <QToggleField {...props} name="presale" label={i18n('ListFilterForm.preSaleLabel')} />;
};

const SaleSearchField = (props: Omit<QToggleFieldProps, 'name' | 'label'>) => {
  return <QToggleField {...props} name="sale" label={i18n('ListFilterForm.onSaleLabel')} />;
};

const NoDescriptionSearchField = (props: Omit<QToggleFieldProps, 'name' | 'label'>) => {
  return <QToggleField {...props} name="is_no_des" label="No description" />;
};

const OosSearchField = (props: Omit<QToggleFieldProps, 'name' | 'label'>) => {
  return <QToggleField {...props} name="is_oos" label="Out of stock" />;
};
