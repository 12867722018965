import * as React from 'react';

import lang from './lang';

import type { FormProps, TFormCallbackProps, TFormUpdateProps } from '@miq/formjs';
import type { CategoryChoicesType } from '../../types';
import type { TStaffProductInstance } from '../models';

import { Button } from '@miq/componentjs';
import { isRequired as isR, TLang } from '@miq/utiljs';
import Form, { useForm } from '@miq/formjs';

import { SupplierOrder } from '../../supplier';
import { StaffProduct } from '../models';

import * as ProductFields from './fields';

export const i18n = (k: keyof TLang) => window.i18n(lang, k);

/**
 *
 */

interface IProductUpdateFormProps extends TFormUpdateProps<{ instance: TStaffProductInstance }> {
  onWillSubmit?: () => void;
}

export const ProductQuickUpdateForm = (
  props: Omit<IProductUpdateFormProps, 'context'> & { categories: CategoryChoicesType; orderSlug?: string }
) => {
  const { instance, categories, orderSlug, ...rest } = props;

  const form = useForm({
    name: instance.name || '',
    category: instance.category || '',
    description: instance.description || '',
    retail_price: instance?.retail_price || 0.0,
    is_pre_sale: instance.is_pre_sale || false,
    is_pre_sale_text: instance.is_pre_sale_text || '',
  });

  const { errors } = form;
  const { fields = ['name', 'category', 'description', 'retail_price', 'is_pre_sale', 'is_pre_sale_text'] } = props;

  return (
    <ProductUpdateForm {...rest} context={form} instance={instance} fields={fields}>
      <ProductForm.NameField error={errors.name} />

      <ProductForm.DescriptionField error={errors.description} />

      <div className="d-grid grid-md-3 gap-2">
        {categories && <ProductForm.CategoryField categories={categories} error={errors.category} />}
        <ProductForm.RetailPriceField error={errors.retail_price} />
      </div>

      <ProductForm.PresaleCheckboxField label={i18n('PresaleField.label')} error={errors.is_pre_sale} />
      {form.values.is_pre_sale && (
        <ProductForm.PresaleTextField
          label={i18n('PresaleTextField.label')}
          placeholder={i18n('PresaleTextField.placeholder')}
          error={errors.is_pre_sale_text}
        />
      )}

      <div className="d-flex align-items-center justify-content-between">
        <div>
          {orderSlug && instance?.slug && (
            <Button
              onClick={() => {
                SupplierOrder({ slug: orderSlug }).destroyItem(instance.slug!);
              }}
            >
              {i18n('QUpdateForm.removeFromOrderLabel')}
            </Button>
          )}
        </div>

        <Form.Submit value={i18n('QUpdateForm.submitLabel')} className="btn-primary-3 btn-md" />
      </div>
    </ProductUpdateForm>
  );
};

export function ProductUpdateForm({ children, ...props }: IProductUpdateFormProps) {
  const {
    instance = isR('StaffProduct instance'),
    fields = isR('fields instance'),
    onSuccess,
    onError,
    ...rest
  } = props;

  const { context } = rest;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props?.onWillSubmit?.();

    const fd: any = {};
    fields.forEach((field) => {
      const value = context.values[field];
      if (value == null) return;
      if (field == 'is_pre_sale_dt' && !value) return;

      fd[field] = value;
    });

    return instance
      .update(fd)
      .then((r) => onSuccess?.(r))
      .catch((err: any) => {
        if (onError) return onError(err);
        return context.handleError(err);
      });
  };

  return (
    <ProductForm {...rest} onSubmit={handleSubmit}>
      {children}
    </ProductForm>
  );
}

/**
 *
 */

export interface ProductCreateFormProps extends TFormCallbackProps {}

export const ProductCreateForm = (props: ProductCreateFormProps) => {
  const form = useForm({ name: '', retail_price: '', description: '' });

  const { errors } = form;

  const handleCreate = (e: React.FormEvent) => {
    e.preventDefault();
    StaffProduct({})
      .create(form.values)
      .then((res: any) => props?.onSuccess?.(res))
      .catch((err: any) => form.handleError(err));
  };

  return (
    <ProductForm context={form} onSubmit={handleCreate}>
      <ProductForm.NameField label={i18n('NField.label')} error={errors.name} />
      <ProductForm.RetailPriceField required label={i18n('RetailPField.label')} error={errors.retail_price} />
      <ProductForm.DescriptionField label={i18n('DField.label')} error={errors.description} />

      <Form.Submit value={i18n('CreateForm.submitLabel')} className="btn-primary-3" />
    </ProductForm>
  );
};

/**
 *
 */

export const ProductForm = (props: FormProps) => <Form {...props} />;

/**
 *
 */

ProductForm.NameField = ProductFields.NameField;
ProductForm.DescriptionField = ProductFields.DescriptionField;
ProductForm.CategoryField = ProductFields.CategoryField;
ProductForm.SalePriceField = ProductFields.SalePriceField;
ProductForm.OnSaleCheckboxField = ProductFields.OnSaleCheckboxField;
ProductForm.OosCheckboxField = ProductFields.OosCheckboxField;
ProductForm.PresaleTextField = ProductFields.PresaleTextField;
ProductForm.RetailPriceField = ProductFields.RetailPriceField;
ProductForm.StageSelectField = ProductFields.StageSelectField;
ProductForm.PresaleCheckboxField = ProductFields.PresaleCheckboxField;
ProductForm.IsExplicitCheckboxField = ProductFields.IsExplicitCheckboxField;
