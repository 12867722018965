import * as React from 'react';

import { Icons } from '@miq/componentjs';
import { getCN, isFunction, debounce, isRequired } from '@miq/utiljs';
import FormCtx, { TFormCtx } from './context';

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {}

export interface InputRefProps extends React.ComponentPropsWithRef<'input'> {}

export type FormInputProps = InputProps & {
  error?: any;
  mirror?: string;
  onDebounce?: (p: { name: string; value: string; e: React.ChangeEvent<HTMLInputElement>; ctx: TFormCtx }) => void;
  wait?: number;
};

const withFormTextInput = (Component: any, args: any = {}) =>
  React.forwardRef<HTMLInputElement, FormInputProps>((props, ref) => {
    const ctx: TFormCtx = React.useContext(FormCtx) || isRequired('FormCtx');

    let { name, error, onDebounce, wait, ...rest } = props;

    const debounceOnSave = React.useRef(
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.required && !e.target.value) return;
        if (!onDebounce || !isFunction(onDebounce)) return;
        return onDebounce({ name: e.target.name, value: e.target.value, e, ctx });
      }, wait || 500)
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      ctx.handleChange(e);

      //
      if (!debounceOnSave.current) return;
      debounceOnSave.current(e);
    };

    error = error || ctx.errors[name!];
    const className = getCN([args.className, props.className, error && 'is-invalid']);

    return (
      <Component
        value={ctx.values[name!]}
        onChange={onChange}
        {...args.input}
        {...rest}
        name={name}
        className={className}
        ref={ref}
      />
    );
  });

export const FormTextInput = withFormTextInput('input', { className: 'miq-form-input' });
export const FormTextArea = withFormTextInput('textarea', { className: 'miq-form-textarea' });

export type SearchInputProps = InputProps & {
  inputClassName?: string;
};

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => {
  const { id, className, inputClassName, ...rest } = props;
  return (
    <div {...{ id }} className={getCN(['miq-search-input-wrapper d-flex align-items-center', className])}>
      <div className="icon-wrapper">
        <Icons.Search className="miq-search-icon" />
      </div>

      <input
        name="q"
        placeholder="Search ..."
        minLength={3}
        maxLength={99}
        className={getCN(['miq-form-input miq-search-input', inputClassName])}
        {...rest}
        type="search"
        ref={ref}
      />
    </div>
  );
});

//#region PhoneInput

/**
 * Phone input
 * Usage:
 * ```js
 * <PhoneInput onchange={(e)=>setState(e.target.value)} minLength={7} maxLength={16} pattern="[0-9]*"/>
 * ```
 */

export const FormPhoneInput = withFormTextInput('input', {
  className: 'miq-form-input',
  input: {
    minLength: 7,
    maxLength: 16,
    pattern: '[0-9]*',
    type: 'tel',
    onKeyPress: (e: React.KeyboardEvent) => !/[0-9]/.test(e.key) && e.preventDefault(),
  },
});

//#endregion PhoneInput

export type TextAreaXProps = InputProps & {
  clearFocus?: string | boolean;
  mirror?: string;
};

export const TextAreaX = React.forwardRef<HTMLTextAreaElement, TextAreaXProps>((props, ref) => {
  const { id, clearFocus, value, onChange, placeholder = 'Start typing ...', mirror, ...rest } = props;
  const style = props.style;

  return (
    <div id={id} className={getCN(['miq-textareax', clearFocus && 'clear-focus'])}>
      {mirror ? (
        <span
          className="miq-textareax-input-mirror"
          {...{ style }}
          dangerouslySetInnerHTML={{ __html: `${mirror}\n` }}
        />
      ) : (
        <span className="miq-textareax-input-mirror" {...{ style }}>{`${value}\n`}</span>
      )}

      <div className="miq-textareax-input-wrapper">
        <textarea {...rest} {...{ value, onChange, placeholder, style }} ref={ref} />
      </div>
    </div>
  );
});

export const FormTextAreaX = withFormTextInput(TextAreaX);

//#region SelectInput

export type FormSelectInputProps = FormInputProps & {
  clearFocus?: string | boolean;
  nullValue?: any;
};

export const FormSelectInput = React.forwardRef<HTMLInputElement, FormSelectInputProps>(
  ({ children, ...props }, ref) => {
    const ctx: TFormCtx = React.useContext(FormCtx) || isRequired('FormCtx');
    let { name, error, nullValue, ...rest } = props;

    error = error || ctx?.errors?.[name!];

    return (
      <select
        value={ctx?.values?.[name!]}
        onChange={ctx.handleChange}
        {...rest}
        name={name}
        className={getCN(['miq-form-select', error && 'is-invalid', rest.className])}
        ref={ref}
      >
        {nullValue?.label && <Option {...nullValue} value={nullValue?.value || ''} />}
        {children}
      </select>
    );
  }
);

interface OptionProps extends React.ComponentPropsWithoutRef<'option'> {
  value: string | number;
  // label?: string | any;
}

export const Option = ({ label, children, ...props }: OptionProps) => (
  <option {...props} value={props.value}>
    {children || label}
  </option>
);

//#endregion SelectInput

//#region CheckboxInput

export type FormCheckboxInputProps = FormInputProps & {};

export const FormCheckboxInput = React.forwardRef<HTMLInputElement, FormCheckboxInputProps>((props, ref) => {
  const ctx: TFormCtx = React.useContext(FormCtx) || isRequired('FormCtx');
  let { name = isRequired('Name prop'), error } = props;

  error = error || ctx?.errors?.[name];

  const handleChange = (e) => {
    if (ctx.errors[name]) ctx.setError(name, null);

    if (props.onChange) props?.onChange(e);
    else ctx.toggleCheck(e);
  };

  return (
    <input
      onChange={handleChange}
      checked={ctx.values[name]}
      {...props}
      type="checkbox"
      className={getCN(['miq-form-checkbox', error && 'is-invalid', props.className])}
      ref={ref}
    />
  );
});

//#endregion CheckboxInput
