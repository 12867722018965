import * as React from 'react';

import { Button, ConfirmButton, Icons } from '@miq/componentjs';
import { TFormUpdateProps } from '@miq/formjs';
import { getCN } from '@miq/utiljs';

import { TCart, TCartInstance } from './utils';
import Staff from '@miq/staffjs';
import { LinkProps } from 'react-router-dom';

export const getCartPath = (cart: TCartInstance | TCart) => {
  let path = `/staff/sales/carts/${cart.slug}/`;
  if (cart.is_paid) return `${path}paid/`;
  return path;
};

// --------------------------------------------------------------------------------
// BUTTONS
// --------------------------------------------------------------------------------

export const CartPayButton: React.FC<Omit<TFormUpdateProps<{ label: string; instance: TCartInstance }>, 'context'>> = (
  props
) => {
  const { instance, onSuccess, onError, label } = props;

  const handlePay = (setOpen: any) => {
    instance
      .mark_paid()
      .then((res) => {
        setOpen?.(false);
        onSuccess?.(res);
      })
      .catch((err) => onError?.(err));
  };

  return (
    <ConfirmButton
      disabled={instance.products!.length <= 0}
      title={'Place cart'}
      renderHeader={() => <div className="text-center fw-bold p-1">Placer cette commande?</div>}
      renderFooter={({ setOpen }) => (
        <div className="d-flex justify-content-between align-items-center p-2">
          <Button onClick={() => setOpen(false)}>Annuler</Button>

          <Button onClick={() => handlePay(setOpen)} className="btn-primary fw-bold w-50">
            Valider
          </Button>
        </div>
      )}
      render={() => <div className="p-3">Une commande placée ne peut plus être supprimée.</div>}
      className={getCN(['btn-primary btn-md fw-bold w-50', props.className])}
    >
      {label && <span className="label">{label}</span>}
    </ConfirmButton>
  );
};

export const CartItemDeleteButton = ({
  cartInstance,
  itemSlug,
  ...props
}: {
  itemSlug: string;
  cartInstance: TCartInstance;
  onSuccess?: (res?: any) => void;
  onError?: (res?: any) => void;
}) => {
  if (!itemSlug) return null;

  return (
    <ConfirmButton
      renderHeader={() => <div>Supprimer ce produit</div>}
      renderFooter={({ setOpen }) => (
        <div className="d-flex justify-content-between align-items-center">
          <Button onClick={() => setOpen(false)}>Annuler</Button>

          <Button
            onClick={() =>
              cartInstance
                .destroyItem(itemSlug)
                .then((r) => {
                  props?.onSuccess?.(r);
                  // setOpen(false);
                })
                .catch((err) => {
                  props?.onError?.(err);
                })
            }
            className="btn-danger"
          >
            Supprimer
          </Button>
        </div>
      )}
      render={() => <div className="p-3">Voulez-vous supprimer ce produit</div>}
      className={getCN(['cart-item-delete-button btn-danger-3'])}
    >
      <Icons.Trash />
    </ConfirmButton>
  );
};

export const CartDeleteButton: React.FC<
  Omit<TFormUpdateProps<{ label?: string; instance: TCartInstance }>, 'context'>
> = (props) => {
  const { instance, onSuccess, onError, label } = props;

  const handleDelete = (setOpen: any) => {
    if (!instance.canDestroy) return;

    instance
      .destroy()
      .then((res) => {
        onSuccess?.(res);
        setOpen?.(false);
        // emitter.destroy({ slug: instance.slug });
      })
      .catch((err: any) => onError?.(err));
  };

  return (
    <ConfirmButton
      disabled={!instance.canDestroy}
      title={instance.canDestroy ? 'Delete this cart' : "Can't delete a cart with items"}
      renderHeader={() => <div>Supprimer ce panier</div>}
      renderFooter={({ setOpen }) => (
        <div className="d-flex justify-content-between align-items-center">
          <Button onClick={() => setOpen(false)}>Annuler</Button>

          <Button onClick={() => handleDelete(setOpen)} className="btn-danger">
            Supprimer
          </Button>
        </div>
      )}
      render={() => <div className="p-3">Are you sure you want to delete this</div>}
      className={getCN(['cart-delete-button btn-danger-3', props.className])}
    >
      <Icons.Trash />
      {label && <span className="label">{label}</span>}
    </ConfirmButton>
  );
};

// --------------------------------------------------------------------------------
// LINKS
// --------------------------------------------------------------------------------

export const CartUpdateLink = ({
  instance,
  children,
  ...props
}: Omit<LinkProps, 'to'> & { instance: TCart | TCartInstance; anchor?: boolean }) => {
  return (
    <Staff.Link
      title={`Modifier ce panier`}
      {...props}
      to={getCartPath(instance)}
      requiredPerms={['sales.change_cart']}
    >
      {children}
    </Staff.Link>
  );
};

export const CartAddLink = () => (
  <Staff.Link
    to="/staff/sales/carts/cart"
    className="btn btn-primary btn-round"
    title="Ajouter un panier"
    requiredPerms={['sales.add_cart']}
  >
    <Icons.ShoppingCartPlus />
  </Staff.Link>
);
