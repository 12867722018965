import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import { SharedDataCtx, TSDState, useReloadSharedData } from '@miq/contextjs';
import { TStoreSettings } from './types';

const ProductRoutes = React.lazy(() => import('./product'));
const CategoryRoutes = React.lazy(() => import('./category'));
const SupplierRoutes = React.lazy(() => import('./supplier'));
const InsightsRoutes = React.lazy(() => import('./insights'));

const ShopSettingUpdateView = React.lazy(() => import('./setting'));
const ShopStaffIndexView = React.lazy(() => import('./views.Index'));
const ShopNotificationView = React.lazy(() => import('./views.Notification'));

export default function ShopStaffRoutes() {
  useReloadSharedData();
  const ctx = React.useContext<TSDState<{ shopy_settings?: TStoreSettings }>>(SharedDataCtx);

  const { shopy_settings } = ctx;
  if (!shopy_settings) return null;

  return (
    <Routes>
      <Route path="insights/*" element={<InsightsRoutes />} />
      <Route path="orders/*" element={<SupplierRoutes />} />
      <Route path="products/*" element={<ProductRoutes />} />
      <Route path="categories/*" element={<CategoryRoutes />} />

      <Route path="notifications/" element={<ShopNotificationView />} />
      <Route path="settings/" element={<ShopSettingUpdateView />} />
      <Route index element={<ShopStaffIndexView />} />
    </Routes>
  );
}

export type {
  TPriceData,
  TPrice,
  TProduct,
  TProductStageData,
  TSupplierOrder,
  TProductSize,
  TProductAttribute,
  TCategory,
  CategoryChoicesType,
  SupplierItemType,
  TCurrency,
  TStoreSettings,
} from './types';

export { useProductListRequest } from './product/utils';
export { ProductListFilterForm } from './product/forms/form.PListFilter';
export { ProductListItem } from './product/components';
export { ProductPriceDisplay } from './components';
export { SizeQuantityField, SizeSelectField } from './productSize';
