import * as React from 'react';
import { getCN } from '@miq/utiljs';

import './card.scss';

type NodeOrString = React.ReactNode | string;

export type TCardProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> & {
  title?: NodeOrString;
  text?: NodeOrString;
  actions?: NodeOrString;
  footer?: NodeOrString;
  headerclassName?: string;
  bodyclassName?: string;
  footerclassName?: string;
};

/**
 * View Card
 * Usage:
 * ```js
 * <ViewCard title="View title" text="This is a title" actions={<Button>Hello</Button>} footer={<div>My footer</div>}>
 *  Click to show a message
 * </ViewCard>
 * ```
 */

export function Card({ children, ...props }: TCardProps) {
  const { title, text, actions, footer } = props;
  const hasHeader = title || text || actions;

  return (
    <div id={props.id} className={getCN(['view-card', props.className])}>
      {hasHeader && (
        <div className={getCN(['view-card-header', props.headerclassName])}>
          <div className="d-flex align-items-center">
            <div className="view-card-title flex-1">{title}</div>

            {actions && <div className="view-card-actions">{actions}</div>}
          </div>

          {text && <div className="view-card-text">{text}</div>}
        </div>
      )}

      <div className={getCN(['view-card-body', props.bodyclassName])}>{children}</div>

      {footer && <div className={getCN(['view-card-footer', props.footerclassName])}>{footer}</div>}
    </div>
  );
}
