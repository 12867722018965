import React from 'react';

import { getCN } from '@miq/utiljs';

import { FormLabel, FormError, FormErrorProps } from './components';
import { FormCheckboxInputProps, FormInputProps, FormSelectInputProps, FormTextAreaX, FormTextInput } from './Inputs';

type TField = FormFieldProps & { fieldCN?: string };
export type TextFieldProps = TField & FormInputProps;
export type SelectFieldProps = TField & FormSelectInputProps;
export type CheckboxFieldProps = TField & FormCheckboxInputProps;

export const FirstNameField = ({ label = 'Prénom', text, error, fieldCN, ...props }: TextFieldProps) => (
  <Field {...{ label, error, text }} className={fieldCN}>
    <FormTextInput name="first_name" maxLength={99} {...props} minLength={2} />
  </Field>
);

export const LastNameField = ({ label = 'Nom', text, error, fieldCN, ...props }: TextFieldProps) => (
  <Field {...{ label, error, text }} className={fieldCN}>
    <FormTextInput name="last_name" maxLength={99} {...props} minLength={2} />
  </Field>
);

export const NameField = (props: TextFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Field {...{ label, text, error }}>
      <FormTextInput maxLength={99} {...rest} name="name" />
    </Field>
  );
};

export const EmailField = ({ label = 'Email', text, error, fieldCN, ...props }: TextFieldProps) => (
  <Field {...{ label, text, error }} className={fieldCN}>
    <FormTextInput type="email" {...props} name="email" />
  </Field>
);

export const DescriptionField = (props: TextFieldProps) => {
  const { label, text, error, ...rest } = props;
  return (
    <Field {...{ label, text, error }}>
      <FormTextAreaX {...rest} name="description" />
    </Field>
  );
};

/**
 * Meta fields
 */

export const MetaTitleField = (props: TextFieldProps) => {
  const { label = 'Meta title', text, error, ...rest } = props;

  return (
    <Field {...{ label, text, error }}>
      <FormTextInput required maxLength={99} {...rest} name="meta_title" />
    </Field>
  );
};

export const MetaSlugField = (props: TextFieldProps) => {
  const { label = 'Meta slug', text, error, ...rest } = props;

  return (
    <Field {...{ label, text, error }}>
      <FormTextInput required maxLength={99} {...rest} name="meta_slug" />
    </Field>
  );
};

export const MetaDescriptionField = (props: TextFieldProps) => {
  const { label = 'Meta description', text, error, ...rest } = props;
  return (
    <Field {...{ label, text, error }}>
      <FormTextAreaX {...rest} name="meta_description" />
    </Field>
  );
};

export type FormFieldProps = FormErrorProps &
  React.PropsWithChildren<{
    label?: React.ReactNode | string;
    text?: React.ReactNode | string;
    checkbox?: string | boolean;
    className?: string;
  }>;

export default function Field({ children, label, text, error, checkbox, ...props }: FormFieldProps) {
  const hasLabel = label || text;

  if (checkbox) {
    return (
      <div className={getCN(['miq-form-field', props.className])}>
        <div className="d-flex align-items-center">
          <div className="me-1">{children}</div>
          <FormLabel value={label} />
        </div>
        {text && <div className="miq-form-field-text">{text}</div>}
        <FormError error={error} />
      </div>
    );
  }

  return (
    <div className={getCN(['miq-form-field', props.className])}>
      {hasLabel && (
        <div className="miq-form-field-header">
          {label && <FormLabel value={label} />}
          {text && <div className="miq-form-field-text">{text}</div>}
        </div>
      )}
      {children}
      <FormError error={error} />
    </div>
  );
}
