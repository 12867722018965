import React from 'react';

import { getCN } from '@miq/utiljs';

import './loaders.scss';

export interface LoaderProps extends React.ComponentPropsWithoutRef<'div'> {
  icon?: boolean | string;
}

export const Loading: React.FC<LoaderProps> = ({ id, className, icon }) => (
  <div id={id} className={getCN(['miq-loader', icon && 'small icon', className])}>
    <CubeGrid className={className} />
  </div>
);

export const CubeGrid: React.FC<LoaderProps> = (props) => (
  <div className={getCN(['sk-cube-grid', props.className])}>
    <div className="sk-cube sk-cube1"></div>
    <div className="sk-cube sk-cube2"></div>
    <div className="sk-cube sk-cube3"></div>
    <div className="sk-cube sk-cube4"></div>
    <div className="sk-cube sk-cube5"></div>
    <div className="sk-cube sk-cube6"></div>
    <div className="sk-cube sk-cube7"></div>
    <div className="sk-cube sk-cube8"></div>
    <div className="sk-cube sk-cube9"></div>
  </div>
);
