import * as React from 'react';

import { SharedDataCtx, TSDState } from '@miq/contextjs';

export const useDocumentTitle = (defaultTitle: string) => {
  const { site = {} } = React.useContext<TSDState>(SharedDataCtx);
  const [title, setTitle] = React.useState<string>(`${defaultTitle}${'name' in site ? ` | ${site.name}` : ''}`);

  React.useEffect(() => {
    document.title = title;
  }, [title]);

  return [title, setTitle];
};
