import * as React from 'react';

export type FormLabelProps = React.PropsWithChildren<{ value?: React.ReactNode | string }>;

export const FormLabel = ({ children, value, ...props }: FormLabelProps) => {
  const Comp = children ? children : typeof value === 'string' ? <label {...props}>{value}</label> : value;
  return <div className="miq-form-label">{Comp}</div>;
};

export type FormErrorProps = { error?: any };

export const FormError = ({ error }: FormErrorProps) => {
  if (!error) return null;
  return <div className="miq-form-error">{error}</div>;
};
