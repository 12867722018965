import * as React from 'react';

import { getCN, getImgUrl } from '@miq/utiljs';

import './img.scss';

//#region COMPONENTS

export type TImg = {
  slug?: string;
  src?: string;
  height?: number;
  width?: number;
  size?: string;

  alt_text?: string;
  caption?: string;
  position?: number;

  thumb?: string;
  height_thumb?: number;
  width_thumb?: number;
  size_thumb?: string;
  thumb_sq?: string;
  height_thumb_sq?: number;
  width_thumb_sq?: number;
  size_thumb_sq?: string;
  src_mobile?: string;
  height_mobile?: number;
  width_mobile?: number;
  size_mobile?: string;

  // id?: string;
  // className?: string;
  name?: string;
  name_truncated?: string;
  created?: string;
  updated?: string;
  title?: string;
  aria_label?: string;
};

export type ImgProps = React.ComponentPropsWithoutRef<'img'> & TImg;

type GalleryProps = { images?: TImg[]; className?: string; id?: string };

export type HorizontalGalleryProps = GalleryProps & { mobileOnly?: string | boolean };

const HorizontalGallery: React.FC<HorizontalGalleryProps> = ({ images = [], mobileOnly, ...props }) => {
  if (!images) return null;

  return (
    <div id={props.id} className={getCN(['miq-imgs-gallery-horizontal', props.className])}>
      <div className={getCN(['miq-imgs-gallery-horizontal-inner', mobileOnly && 'md-vertical'])}>
        {images.map((img) => {
          return (
            <div className="miq-imgs-gallery-horizontal-item" key={img.slug || img.src}>
              <Picture {...img} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

type VerticalGalleryProps = GalleryProps & {};

const VerticalGallery: React.FC<VerticalGalleryProps> = ({ images = [], ...props }) => {
  return (
    <div id={props.id} className={getCN(['imgs-gallery-vertical', props.className])}>
      {images.map((img) => {
        return (
          <div className="gallery-item mb-1" key={img.slug || img.src}>
            <Picture {...img} />
          </div>
        );
      })}
    </div>
  );
};

const Mobile: React.FC<ImgProps> = (props) => {
  const src = getImgUrl(props.src);
  const thumb = getImgUrl(props.thumb);
  const thumb_sq = getImgUrl(props.thumb_sq);
  const src_mobile = getImgUrl(props.src_mobile);

  return (
    <div className="miq-img-mobile-wrapper">
      <Image
        {...props}
        src={src_mobile || src}
        thumb={src_mobile || thumb}
        thumb_sq={src_mobile || thumb_sq}
        className={getCN(['miq-img-mobile', props.className])}
      />
    </div>
  );
};

const Thumb: React.FC<ImgProps> = (props) => {
  return (
    <div className="miq-img-thumb-wrapper">
      <Image
        {...props}
        src={props.thumb || props.src}
        thumb_sq={props.thumb || props.thumb_sq}
        src_mobile={props.thumb || props.src_mobile}
        className={getCN(['miq-img-thumb', props.className])}
      />
    </div>
  );
};

const Square: React.FC<ImgProps> = (props) => {
  return (
    <div className="miq-img-square-wrapper">
      <Image
        {...props}
        src={props.thumb_sq || props.src}
        thumb={props.thumb_sq || props.thumb}
        src_mobile={props.thumb_sq || props.src_mobile}
        className={getCN(['miq-img-square', props.className])}
      />
    </div>
  );
};

const Picture: React.FC<ImgProps> = (props) => {
  const src = getImgUrl(props.src);
  const src_mobile = getImgUrl(props.src_mobile);

  return (
    <picture>
      <source srcSet={src} media={`(min-width: 768px)`} />
      <Image {...props} src={src_mobile || src} className={getCN(['miq-img-picture', props.className])} />
    </picture>
  );
};

const Image: React.FC<ImgProps> = (props) => {
  let { thumb } = props;
  const src = getImgUrl(props.src);
  thumb = getImgUrl(thumb);
  const src_mobile = getImgUrl(props.src_mobile);

  const sizes = `(max-width: 640px) ${props.width_mobile}px, (max-width: 768px) ${props.width_thumb}px, ${props.width}px`;
  const srcSet = `${src_mobile} ${props.width_mobile}w, ${thumb} ${props.width_thumb}w, ${src} ${props.width}w`;
  const alt = props.alt_text || props.title || '';
  const ariaLabel = props.aria_label || props.title;

  let orientation;
  const { width, height } = props;
  if (width && height) orientation = width < height ? 'portrait' : width === height ? 'square' : 'landscape';

  return (
    <img
      srcSet={thumb ? srcSet : ''}
      sizes={thumb ? sizes : ''}
      src={src}
      alt={alt}
      title={props.title}
      aria-label={ariaLabel}
      className={getCN(['miq-img', orientation, props.className])}
      style={props?.style}
    />
  );
};

//#endregion COMPONENTS

//#region Img

export const Img = (data: any) => Object.assign({}, data);

Img.HorizontalGallery = HorizontalGallery;
Img.VerticalGallery = VerticalGallery;
Img.Mobile = Mobile;
Img.Thumb = Thumb;
Img.Square = Square;
Img.Picture = Picture;
Img.Image = Image;

//#endregion Img
