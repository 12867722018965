import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Icons } from '@miq/componentjs';

import StaffView from '../Views';
import { StaffViewNavLinks } from '../components';

const LIBRoutes = React.lazy(() => import('./libs'));
const HitRoutes = React.lazy(() => import('./hits'));
const IndexView = React.lazy(() => import('./views.Index'));

export function AnalyticsStaffRoutes() {
  return (
    <StaffView back="/staff/" title="Analytics" mainCN="no-bg p-0" className="analytics-view">
      <div className="my-2">
        <StaffViewNavLinks
          navLinks={[
            { label: '', icon: <Icons.Home style={{ verticalAlign: -5 }} />, link: { to: './' } },
            { label: 'LIB', link: { to: './libs' } },
            { label: 'Hits', link: { to: './hits' } },
          ]}
        />
      </div>

      <Routes>
        <Route path="/libs/*" element={<LIBRoutes />} />
        <Route path="/hits/*" element={<HitRoutes />} />
        <Route path="/" element={<IndexView />} />
      </Routes>
    </StaffView>
  );
}

export { HitListItem } from './hits';
