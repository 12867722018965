import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import * as Axios from 'axios';

// https://elijahmanor.com/blog/react-blessed-userequest

// https://hooks.umijs.org/hooks/async#refreshdeps
// https://github.com/alibaba/hooks/blob/master/packages/hooks/src/useRequest/src/useRequestImplement.ts

export type baseOptionsType = {
  refreshDeps?: any[];
  onSuccess?: (res?: any) => void;
  onError?: (errRes?: any) => void;
};

const baseOptions: baseOptionsType = {
  refreshDeps: [],
};

type Resp<TData = any> = Axios.AxiosResponse<TData, any> & { loading: boolean };

const defaultResponse: Resp = {
  loading: true,
  status: 0,
  statusText: '',
  config: {},
  data: null,
  headers: {},
  request: null,
};

const st = new Map<any, any>();

export function useRequest<T = any>(
  service: () => Promise<Axios.AxiosResponse<T>> | null,
  options: baseOptionsType = baseOptions
) {
  const [res, dispatch] = useReducer(responseReducer, defaultResponse);

  const isMountedRef = useRef(true);

  const { onSuccess, onError } = options;

  const makeRequest = useMemo(
    () => () => {
      const s = service?.();
      if (!s) {
        dispatch({ type: 'NOTLOADING' });
        return;
      }

      dispatch({ type: 'LOADING' });

      return s
        .then((response: Axios.AxiosResponse<T>) => {
          if (!isMountedRef.current) return null;

          dispatch({ type: 'SET_RES', payload: response });
          onSuccess?.(response);
        })
        .catch((err: Axios.AxiosResponse) => {
          if (!isMountedRef.current) return null;
          dispatch({ type: 'SET_ERR', payload: err });
          onError?.(err);
          // throw err;
        });
    },
    [service, onSuccess, onError]
  );

  const { refreshDeps } = options;

  const deps = useMemo(() => {
    if (Array.isArray(refreshDeps)) {
      return [...refreshDeps, makeRequest];
    }
    return [makeRequest];
  }, refreshDeps);

  useEffect(() => {
    makeRequest();
  }, deps);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, [isMountedRef]);

  return {
    res: res,
    loading: res.loading,

    addItem: (payload: any) => dispatch({ type: 'ADD_ITEM', payload }),
    updateItem: (payload: any) => dispatch({ type: 'UPDATE_ITEM', payload }),
    removeItem: (payload: any) => dispatch({ type: 'REMOVE_ITEM', payload }),

    updateData: (payload: any) => dispatch({ type: 'UPDATE_DATA', payload }),
    setData: (payload: any) => dispatch({ type: 'SET_DATA', payload }),
    removeData: () => dispatch({ type: 'REMOVE_DATA' }),

    setLoading: () => dispatch({ type: 'LOADING' }),
    setNotLoading: () => dispatch({ type: 'NOTLOADING' }),
    setRes: (payload: any) => dispatch({ type: 'SET_RES', payload }),
    setError: (payload: any) => dispatch({ type: 'SET_ERR', payload }),

    refresh: makeRequest,
  };
}

const hasKey = (obj: any, key: string) => Object.keys(obj).includes(`${key}`);

interface TReducerAction<TState> {
  ADD_ITEM: { payload: TState & { slug: string; append?: boolean } };
  UPDATE_ITEM: { payload: TState & { slug: string } };
  REMOVE_ITEM: { payload: TState & { slug: string } };
  SET_DATA: { payload: TState };
  UPDATE_DATA: { payload: TState };
  REMOVE_DATA: {};
  SET_RES: { payload: Axios.AxiosResponse<TState> };
  SET_ERR: { payload: Axios.AxiosResponse<TState> };
  LOADING: {};
  NOTLOADING: {};
}

type TReducer<TState extends { loading: boolean }> = (
  state: TState,
  action: {
    [TActionType in keyof TReducerAction<TState>]: {
      type: TActionType;
    } & TReducerAction<TState>[TActionType];
  }[keyof TReducerAction<TState>]
) => TState;

const responseReducer: TReducer<Resp['data']> = (state, action) => {
  const { type } = action;
  let data, results;

  switch (type) {
    case 'ADD_ITEM':
      data = state.data;
      if (hasKey(data, 'results')) {
        const { append, ...rest } = action.payload;
        if (append) results = [...data?.results, rest];
        else results = [rest, ...data?.results];

        data = { ...state.data, results, count: state.data.count == null ? 1 : state.data.count + 1 };
      }
      return { ...state, data };
    case 'UPDATE_ITEM':
      data = state.data;
      if (hasKey(data, 'results')) {
        results = data.results.map((i: { slug: string }) => {
          if (i.slug === action.payload?.slug) return action.payload;
          return i;
        });
        data = { ...state.data, results };
      }
      return { ...state, data };
    case 'REMOVE_ITEM':
      data = state.data;
      if (hasKey(data, 'results')) {
        results = data.results.filter((i: { slug: string }) => i.slug !== action.payload.slug);
        data = { ...state.data, results };
      }
      return { ...state, data };

    case 'UPDATE_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'SET_DATA':
      return { ...state, data: action.payload };
    case 'REMOVE_DATA':
      return { ...state, data: null };

    case 'LOADING':
      return { ...state, loading: true };
    case 'NOTLOADING':
      return { ...state, loading: false };

    case 'SET_RES':
    case 'SET_ERR':
      return { ...state, ...action.payload, loading: false };

    default:
      return { ...state };
  }
};

interface IResponse<TData> {
  _res: Axios.AxiosResponse<TData> | null;

  isSuccess: boolean;
  data?: TData;
}

abstract class AbstractResponse<TData extends object> implements IResponse<TData> {
  _res: Axios.AxiosResponse<TData> | null = null;

  constructor(res: Axios.AxiosResponse<TData> | null) {
    this._res = res;
  }

  get isSuccess() {
    const status = this?._res?.status;
    return status && status > 0 && status < 300 ? true : false;
  }

  get data() {
    // if (!this.isSuccess) return null;
    return this._res?.data;
  }
}

export class Response<T extends object = {}> extends AbstractResponse<T> {
  get items() {
    throw new Error('Not implemented');
  }
}

type PaginationProps<TItem> = {
  results: TItem[];
  is_paginated: boolean;
  has_previous: boolean;
  has_previous_page_number: number;
  has_previous_url: string;
  has_next: boolean;
  has_next_page_number: number;
  has_next_url: string;
  count: number;
  num_pages: number;
  page_obj_number: number;
  per_page: number;
  allow_empty_first_page: boolean;
  siblingCount?: number;
  is_drf?: boolean;
};

export class ResultsResponse<TItemData extends object, TDataExtra = any> extends AbstractResponse<
  PaginationProps<TItemData> & TDataExtra
> {
  first(): TItemData {
    return this.items?.[0];
  }

  get items(): TItemData[] {
    const data = this.data;
    if (!data) return [];
    if ('results' in data) return data.results!;
    return [];
  }

  get pagination(): Omit<PaginationProps<TItemData>, 'results'> {
    return {
      is_paginated: this?.data?.is_paginated ?? false,
      has_previous: this?.data?.has_previous ?? false,
      has_previous_page_number: this?.data?.has_previous_page_number ?? 0,
      has_previous_url: this?.data?.has_previous_url ?? '',
      has_next: this?.data?.has_next ?? false,
      has_next_page_number: this?.data?.has_next_page_number ?? 0,
      has_next_url: this?.data?.has_next_url ?? '',
      count: this?.data?.count ?? 0,
      num_pages: this?.data?.num_pages ?? 0,
      page_obj_number: this?.data?.page_obj_number ?? 0,
      per_page: this?.data?.per_page ?? 0,
      allow_empty_first_page: this?.data?.allow_empty_first_page ?? false,
      siblingCount: this?.data?.siblingCount,
      is_drf: this?.data?.is_drf,
    };
  }
}

//

type TArgs<T extends object = {}> = {
  request: () => Promise<T>;
  config: {
    key?: string;
    refreshDeps?: any[];
    onSuccess?: (res?: any) => void;
    onError?: (errRes?: any) => void;
  };
};

//
const cache = new Map<string, unknown>();
//

export const useResponse = <T extends object = {}>(request: TArgs['request'], config?: TArgs['config']) => {
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, [isMountedRef]);

  const [state, setState] = useState<{ loading: boolean; res: any; err: any }>({
    loading: true,
    res: null,
    err: null,
  });

  const { key, onSuccess, onError } = config ?? {};

  const setLoading = useCallback(() => setState({ ...state, loading: true }), [state]);
  const setNotLoading = useCallback(() => setState((state) => ({ ...state, loading: false })), []);

  const setRes = useCallback(
    (res: any) => {
      if (key) {
        cache.set(key, res);
      }
      setState({ err: null, res, loading: false });
    },
    [key]
  );
  const updateRes = useCallback(
    (res: any) => setState((state) => ({ ...state, res: state.res ? { ...state.res, ...res } : res, loading: false })),
    []
  );
  const setErr = useCallback((err: any) => setState({ res: null, err, loading: false }), []);

  const makeRequest = useCallback(() => {
    if (!isMountedRef.current) return;

    if (key && cache.has(key)) {
      console.log('from cache', key);

      setRes(cache.get(key));
    } else {
      console.log('from server', key);

      setLoading();
      request()
        .then((res) => {
          setRes(res);
          onSuccess?.(res);
        })
        .catch((err) => {
          setErr(err);
          onError?.(err);
        });
    }
  }, [key, onSuccess, onError, isMountedRef]);

  const refreshDeps = config?.refreshDeps ? [...config?.refreshDeps, makeRequest] : [makeRequest];

  useEffect(() => {
    makeRequest();
  }, refreshDeps);

  return useMemo(() => {
    return {
      ...state,
      res: state.res ? new Response(state.res) : null,
      err: state.err ? new Response(state.err) : null,
      refresh: makeRequest,
      setLoading,
      setNotLoading,
      setRes,
      updateRes,
      setErr,
    };
  }, [state]);
};
