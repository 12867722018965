import React from 'react';

import styles from './components.module.scss';

import { getCN } from '@miq/utiljs';
import { TFormCallbackProps } from '@miq/formjs';
import { Button, TButtonProps, Icons, ToastEmitter } from '@miq/componentjs';

import { StaffCategory } from './category';
import { TPriceData, TProduct } from './types';
import { StaffProduct, TStaffProductInstance } from './product';

// https://www.bennadel.com/blog/3961-having-fun-with-the-horizontal-usage-of-position-sticky-in-angular-11-0-5.htm

type TUpdateButtonProps = Omit<
  TButtonProps<{
    instance: ReturnType<typeof StaffProduct> | ReturnType<typeof StaffCategory>;
  }>,
  'onError'
> &
  TFormCallbackProps;

export const PublishButton: React.FC<TUpdateButtonProps> = (props) => {
  const { instance, onClick, onSuccess, onFailure, ...rest } = props;

  const handleClick = () => {
    if (instance.is_published) return null;
    return instance
      .publish()
      .then((res) => onSuccess?.(res))
      .catch((err) => onFailure?.(err));
  };

  return (
    <Button onClick={handleClick} className="btn-primary btn-md fw-bold" {...rest} disabled={instance.is_published} />
  );
};

export const UnPublishButton: React.FC<TUpdateButtonProps> = (props) => {
  const { instance, onClick, onSuccess, onFailure, ...rest } = props;

  const handleClick = () => {
    if (!instance.is_published) return null;
    return instance
      .unpublish()
      .then((res: any) => onSuccess?.(res))
      .catch((err: any) => onFailure?.(err));
  };

  return (
    <Button className="btn-danger btn-md fw-bold" onClick={handleClick} {...rest} disabled={!instance.is_published} />
  );
};

//#region ================================== COMPONENTS =============================================================

export type PriceDisplayProps = TPriceData & {
  className?: string;
  sale?: string | boolean;
  lineThrough?: string | boolean;
};

export const PriceDisplay = ({ amountWithSymbol, className, sale, lineThrough }: PriceDisplayProps) => (
  <div className={getCN(['miq-price-display', sale && 'text-danger', lineThrough && 'text-line-through', className])}>
    {amountWithSymbol}
  </div>
);

export const ProductPriceDisplay = ({
  retail_price_data,
  is_on_sale,
  sale_price_data,
  inline,
  ...props
}: TProduct & { className?: string; style?: any; inline?: boolean | string }) => {
  if (!retail_price_data) return null;

  const isSale = is_on_sale && sale_price_data?.amountWithSymbol != null;

  return (
    <div className={getCN(['miq-product-price-display', props.className, inline && 'd-flex'])} style={props.style}>
      {isSale && <PriceDisplay sale {...sale_price_data} className="miq-sale-price me-1" />}
      <PriceDisplay {...retail_price_data} lineThrough={isSale} className="miq-retail-price" />
    </div>
  );
};

export const ProductSupplierData = (props: { instance: TStaffProductInstance }) => {
  // const { instance } = props;

  return (
    <div className="supplier-data">
      <div className="item">
        <span className="title">Supplier</span>
        {/* <span className="value">{instance.supplier_name}</span> */}
      </div>
      <div className="item">
        <span className="title">Item ID</span>
        {/* <span className="value">{instance.supplier_item_id}</span> */}
      </div>
      <div className="item">
        <span className="title">Category</span>
        {/* <span className="value">{instance.supplier_item_category}</span> */}
      </div>
      <div className="item">
        <span className="title"> Item cost</span>
        <span className="value">{/* {instance.supplier_item_cost} ({instance.supplier_item_cost_currency}) */}</span>
      </div>
    </div>
  );
};

export const PublishedIcon = ({ show, ...props }: React.ComponentPropsWithoutRef<'svg'> & { show?: boolean }) =>
  show ? <Icons.CheckCircle {...props} className={styles.published_icon} /> : null;

//#endregion ===============================================================================================

export const toast = new ToastEmitter();
