import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

import { useRequest } from '@miq/hookjs';
import { StaffProduct } from './models';
import { TProduct } from '../types';
import { TDRFPaginated } from '@miq/componentjs';

export const useProductListRequest = (
  defaultParams: URLSearchParamsInit = {},
  filterOptions?: { published?: 'include' | 'exclude'; atc?: '1'; supplier_order_slug?: string }
) => {
  if (filterOptions) {
    defaultParams = { ...Object.entries(defaultParams), ...filterOptions };
  }

  const [params] = useSearchParams(defaultParams);

  const is_no_des = params.get('is_no_des');
  const is_oos = params.get('is_oos');
  const q = params.get('q');
  const cat = params.get('cat');
  const page = params.get('page');
  const published = params.get('published');
  const presale = params.get('presale');
  const sale = params.get('sale');

  const { supplier_order_slug } = filterOptions || {};

  return useRequest(() => StaffProduct({}).list<TDRFPaginated<TProduct>>({ params }), {
    refreshDeps: [q, page, cat, published, presale, sale, supplier_order_slug, is_no_des, is_oos],
  });
};
//
