import { EventEmitter } from 'events';
import * as React from 'react';
import { v4 as uuid } from 'uuid';

import { getCN } from '@miq/utiljs';

import './toast.scss';
import { Portal } from '../Modal';

// export const ToastCtx = React.createContext(null);

export type ToastEmitterData = {
  message?: string;
  component?: string;
  timeOut?: number;
  autoClose?: boolean;
  className?: string;
  id?: string;
};

export class ToastEmitter extends EventEmitter {
  success(toastData: ToastEmitterData) {
    this.add('success', toastData);
  }
  info(toastData: ToastEmitterData) {
    this.add('info', toastData);
  }
  warning(toastData: ToastEmitterData) {
    this.add('warning', toastData);
  }
  error(toastData: ToastEmitterData) {
    this.add('error', toastData);
  }
  add(level: string, toastData: ToastEmitterData) {
    this.emit('add', { ...toastData, level, pk: uuid() });
  }
}

export type ToastItemProps = ToastEmitterData & {
  pk: string;
  level: 'success' | 'info' | 'warning' | 'error';
  setItems: React.Dispatch<React.SetStateAction<ToastItemProps[]>>;
};
const ToastItem = (props: ToastItemProps) => {
  const { pk, timeOut = 1500, autoClose = true, setItems, ...comp } = props;

  React.useEffect(() => {
    if (!autoClose) return;

    const removeItem = () =>
      setInterval(() => {
        setItems((items) => items.filter((i) => i.pk !== pk));
      }, timeOut);

    const interval = removeItem();

    return () => {
      clearInterval(interval);
    };
  }, [pk, setItems, autoClose]);

  const { level, message, component, ...rest } = comp;

  if (!message && !component) return null;

  return (
    <div {...rest} className={getCN(['miq-toast-item', level, rest.className])}>
      {component || <div className="miq-toast-msg">{message}</div>}
    </div>
  );
};

export type ToastPortalProps = {
  instance: ToastEmitter;
  position?: string;
};

const ToastPortal = ({ instance = new ToastEmitter(), position = 'top-center' }: ToastPortalProps) => {
  const [items, setItems] = React.useState<ToastItemProps[]>([]);

  React.useEffect(() => {
    instance?.on('add', (data) => {
      setItems((t) => {
        return [data, ...t];
      });
    });
  }, []);

  return (
    <Portal>
      <div className={getCN(['miq-toast-items', position])}>
        {items.map((item) => (
          <ToastItem {...item} {...{ setItems }} key={item.pk} />
        ))}
      </div>
    </Portal>
  );
};

export type ToasterProps = ToastPortalProps & {
  children: React.ReactNode;
};
export function Toaster({ children, instance, ...props }: ToasterProps) {
  return (
    <div className="miq-toaster">
      {children}
      <ToastPortal instance={instance} {...props} />
    </div>
  );
}
