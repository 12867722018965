import * as React from 'react';

import type { TPriceData, TProduct, TProductSize } from '@shopy/storejs';

import Staff from '@miq/staffjs';
import { getCN, truncateStr } from '@miq/utiljs';
import { ProductPriceDisplay } from '@shopy/storejs';

export const ItemProductCard = ({ product, size }: { product: TProduct; size: TProductSize }) => (
  <div className="d-flex align-items-center">
    <ProductImage {...{ product, width: 60, height: 60 }} />

    <div className="flex-1 rounded p-1 ">
      <div className="fw-bold">{truncateStr(product.name!, 0, 25)}</div>
      <ProductPriceDisplay {...product} inline />

      {size && (
        <div>
          <span className="me-1">Size:</span>
          <span className="text-sm">{size?.name}</span>
        </div>
      )}
    </div>
  </div>
);

export const ProductImage = ({
  product,
  ...props
}: {
  product: TProduct;
  width?: number | string;
  height?: number | string;
}) =>
  product?.slug || product.meta_slug ? (
    <div {...props} style={{ width: props.width, height: props.height }}>
      <Staff.Img.Square slug="slug" src="src" {...product.cover_data} className="rounded" />
    </div>
  ) : null;

export const CartPriceListItem = (props: { label: string; amount: TPriceData; bold?: boolean }) => (
  <>
    <span className="cart-price-list-item-label">{props.label}</span>
    <span className={getCN(['cart-price-list-item-amount', props.bold && 'fw-bold'])}>
      <ProductPriceDisplay retail_price_data={props.amount} />
    </span>
  </>
);
