import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { getCN } from '@miq/utiljs';
import Form, { Inputs, useForm } from '@miq/formjs';
import { CustomerFields, TAPICart, TAPIOrderItem, TAPIProduct } from '@shopy/salejs';
import { SizeQuantityField, SizeSelectField } from '@shopy/storejs';

import { patchOrderItem, postOrderItem } from './services';

export const CartForm = (props: { product: TAPIProduct; cart?: TAPICart }) => {
  const form = useForm<TAPICart>({});
  return <Form context={form}></Form>;
};

export const OrderItemForm = ({
  product,
  cart,
  fields,
  onSuccess,
  children,
}: {
  product: TAPIProduct;
  onSuccess: (p: TAPICart) => void;
  fields: string[];
  cart?: TAPICart;
  children?: React.ReactNode;
}) => {
  let { sizes = [], meta_slug: productSlug } = product || {};
  const { items = [] } = cart || {};

  const item = items.find((i: TAPIOrderItem) => i?.product?.meta_slug === productSlug);

  const form = useForm<{
    quantity: number;
    size: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  }>({
    quantity: 1,
    size: item ? item.size : '',

    //# customer
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });

  const { errors, values } = form;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!values.size) {
      form.setError('size', 'Veuillez choisir votre taille');
      return;
    }

    const fd: any = { quantity: values.quantity, size: values.size };

    if (item && item.slug) {
      patchOrderItem(item.slug!, fd).then(({ data }) => {
        onSuccess?.(data);
      });
    } else {
      fd['customer'] = form.values;
      postOrderItem(productSlug!, fd).then(({ data }) => {
        onSuccess?.(data);
      });
    }
  };

  sizes = sizes?.filter((i) => i.quantity > 0);

  return (
    <Form context={form} onSubmit={handleSubmit} className="order-item-form">
      <>
        {fields.includes('size') && <SizeSelectField required label="Taille" error={errors.size} sizes={sizes} />}

        {fields.includes('quantity') && (
          <SizeQuantityField
            error={errors.quantity}
            max={sizes.find((i) => i.slug === form.values.size)?.quantity || 0}
            min={1}
          />
        )}
      </>
      {!Boolean(cart?.customer) && form.values.size && (
        <div className="">
          <CustomerFields.LName error={errors.lastst_name} />
          <CustomerFields.FName error={errors.first_name} />
          <CustomerFields.Phone error={errors.phone} />
          <CustomerFields.Email error={errors.email} />
        </div>
      )}
      {children}
    </Form>
  );
};

export const ProductSearchForm = (props: { className?: string }) => {
  const [search, setSearch] = useSearchParams();

  return (
    <form {...props} className={getCN(['p-search-form', props.className])}>
      <Inputs.SearchInput
        // required
        value={search.get('q') || ''}
        onChange={(e) => {
          setSearch({ q: e.target.value });
        }}
        placeholder="Cherchez un produit ..."
      />
    </form>
  );
};
