import * as React from 'react';

import { getCN } from '@miq/utiljs';
import { useSearchParams } from 'react-router-dom';

export type QSelectFieldProps = React.ComponentProps<'select'> & { emptyLabel?: string; name: string };

export const QSelectField = ({ children, ...props }: QSelectFieldProps) => {
  const [params, setParams] = useSearchParams();
  const { name, emptyLabel } = props;

  return (
    <select
      className={getCN(['miq-form-select', props.className])}
      name={name}
      value={params.get(name) || ''}
      onChange={(e) => {
        const { value } = e.target;

        if (!value && params.has(name)) params.delete(name);
        else params.set(name, value);

        if (params.has('page')) params.delete('page');
        setParams(params);
      }}
    >
      {emptyLabel && <option value="">{emptyLabel}</option>}
      {children}
    </select>
  );
};

export type QToggleFieldProps = React.ComponentProps<'input'> & { label: string; inputId?: string; name: string };

export const QToggleField = (props: QToggleFieldProps) => {
  const [params, setParams] = useSearchParams();
  const { label, name, inputId } = props;

  return (
    <div className={getCN(['miq-query-filter-toggle d-flex align-items-center', props.className])}>
      <input
        type="checkbox"
        className="miq-form-checkbox me-1"
        id={inputId}
        onChange={(e) => {
          const { checked } = e.target;
          if (!checked && params.has(name)) params.delete(name);
          else params.set(name, `${checked}`);

          if (params.has('page')) params.delete('page');

          setParams(params);
        }}
        checked={Boolean(params.get(name)) || false}
      />
      <label htmlFor={inputId}>{label}</label>
    </div>
  );
};
