import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import './buttons.scss';

import { getCN, isFunction as isF } from '@miq/utiljs';
import { ModalWidthTypes, Portal, TModalProps } from '../Modal';

export type TButtonProps<T extends {} = {}> = React.ComponentPropsWithRef<'button'> & T;

export const Button: React.FC<TButtonProps> = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <button type="button" {...props} className={getCN(['btn', props.className])} ref={ref}>
      {children}
    </button>
  );
});

export const RoundBoutton = (props: TButtonProps) => (
  <Button {...props} className={getCN([props.className, 'btn-round'])} />
);

type TB = Omit<TButtonProps, 'value'> & {
  target: string;
  activeCN?: string;
};

type TQueryButtonProps =
  | (TB & {
      index: boolean;
    })
  | (TB & {
      value: string;
    });

/**
 * <QueryButton target="tab" value="config" />
 * <QueryButton index target="tab" activeCN="btn-primary-3"/>
 */

export const QueryButton: React.FC<TQueryButtonProps> = React.forwardRef((props, ref) => {
  const [params, setParams] = useSearchParams();

  const { target, value, index, activeCN, className, ...rest } = props;

  const active = params.get(target) === value;

  const handleClick = () => {
    if (active) return;

    if (index) {
      params.delete(target);
      setParams(params);
    } else if (value) {
      params.set(target, value);
      setParams({ ...Object.fromEntries(params), [target]: value });
    }
  };

  return (
    <Button
      {...rest}
      className={getCN([className, active && (activeCN || 'btn-primary-3')])}
      onClick={handleClick}
      ref={ref}
    />
  );
});

//#endregion QueryButton

//#region ConfirmButton

export type ConfirmButtonProps = TButtonProps<{
  readonly modal?: {
    width?: ModalWidthTypes;
    headerCN?: TModalProps['headerCN'];
    bodyCN?: TModalProps['bodyCN'];
    footerCN?: TModalProps['footerCN'];
  };
  render: (T: { isOpen: boolean; setOpen: (v: boolean) => void }) => void;
  renderHeader: (T: { isOpen: boolean; setOpen: (v: boolean) => void }) => void;
  renderFooter: (T: { isOpen: boolean; setOpen: (v: boolean) => void }) => void;
}>;

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  children,
  render,
  renderHeader,
  renderFooter,
  ...props
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const { modal, ...rest } = props;
  return (
    <>
      <Button {...rest} onClick={() => setOpen(!isOpen)}>
        {children}
      </Button>
      {isOpen && (
        <Portal>
          <Portal.Modal
            {...modal}
            header={isF(renderHeader) && renderHeader({ isOpen, setOpen })}
            footer={isF(renderFooter) && renderFooter({ isOpen, setOpen })}
          >
            {isF(render) && render({ isOpen, setOpen })}
          </Portal.Modal>
        </Portal>
      )}
    </>
  );
};

//#endregion ConfirmButton

// =================================== LINK ========================================== //
