import * as React from 'react';
import { LinkProps } from 'react-router-dom';

import Staff from '@miq/staffjs';
import { getCN } from '@miq/utiljs';

export const ProductUpdateLink = (props: LinkProps) => (
  <Staff.Link
    {...props}
    className={getCN(['product-update-link', props.className])}
    requiredPerms={['store.change_product']}
    title="Edit product"
  />
);

export const ProductUpdateA = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a {...props} className={getCN(['product-update-link', props.className])} title="Edit product" />
);
