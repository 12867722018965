import { StaffModel, StaffService } from '@miq/staffjs';
import type { TProduct } from '../types';

class StaffProductService extends StaffService<TProduct> {
  publish() {
    return this.patch(`${this._path}publish/`, { is_published: true });
  }
  unpublish() {
    return this.patch(`${this._path}publish/`, { is_published: false });
  }
  postSize(values: any) {
    return this.post(`${this._path}size/new/`, values);
  }
  patchSize(sizeSlug: string, values: any) {
    return this.patch(`${this._path}size/${sizeSlug}/`, values);
  }
  deleteSize(sizeSlug: string) {
    return this.delete(`${this._path}size/${sizeSlug}/`);
  }
  postAttribute(values: any) {
    return this.post(`${this._path}attribute/new/`, values);
  }
  patchAttribute(attrSlug: string, values: any) {
    return this.patch(`${this._path}attribute/${attrSlug}/`, values);
  }
  deleteAttribute(attrSlug: string) {
    return this.delete(`${this._path}attribute/${attrSlug}/`);
  }
  swapCover(slug: string) {
    return this.patch(`${this._path}swap-cover/`, { slug });
  }
}

export const StaffProduct = (data: TProduct = {}) => {
  let { dt_published } = data;
  if (dt_published) dt_published = new Date(dt_published);

  return StaffModel<TProduct, StaffProductService>({ ...data, dt_published }, 'products', StaffProductService);
};

export type TStaffProductInstance = ReturnType<typeof StaffProduct>;
