import * as React from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';

import styles from './components.module.scss';

import { getCN } from '@miq/utiljs';
import { Button, Icons, ViewSection } from '@miq/componentjs';
import Staff, { StaffImg } from '@miq/staffjs';

import { TSupplierOrderInstance } from '../supplier';
import { TProduct, TProductStageData } from '../types';
import { ProductPriceDisplay, PublishedIcon } from '../components';

import { StaffProduct, TStaffProductInstance } from './models';
import { ProductPinButton } from './buttons';
import { ProductUpdateLink } from './links';

type TProductListItem = {
  item: TProduct;
  orderInstance?: TSupplierOrderInstance;
  stages: TProductStageData[];
  params?: URLSearchParams;
  onUpdate?: (product: TProduct) => void;
};

export const ProductListItem = ({ item, ...props }: TProductListItem) => {
  const [open, setOpen] = React.useState(false);

  if (!item?.slug) return null;

  const instance = StaffProduct(item);

  return (
    <ViewSection
      border
      header={
        <div onClick={() => setOpen(!open)}>
          <ProductListItemHeader {...props} {...{ instance }} />
        </div>
      }
      className={getCN([styles.listitem])}
    />
  );
};

export const PSTitle = ({ product, ...props }: { product: TProduct | TStaffProductInstance; className?: string }) => {
  return (
    <div
      className={getCN([
        styles.p_title,
        'text-ellipsis',
        props.className,
        product.is_oos ? 'oos' : product.is_pre_sale ? 'presale' : product.is_published ? '' : 'unpublished',
      ])}
    >
      {product.name}
    </div>
  );
};

const ProductListItemHeader = ({
  instance,
  ...props
}: {
  instance: TStaffProductInstance;
  orderInstance?: TSupplierOrderInstance;
  stages: TProductStageData[];
  params?: URLSearchParams;
  onUpdate?: TProductListItem['onUpdate'];
}) => {
  if (!instance.slug) return null;

  const { orderInstance, stages, params } = props;
  const { supplier_item = { cost: 0 }, stage } = instance;

  const stageData = stages?.find((i) => i.value === stage);
  const to = `${instance.slug}/${`${params}` ? `?${params}` : ''}`;

  return (
    <div className={getCN([styles.listitem_header])}>
      <div className="d-flex flex-1">
        <div style={{ width: 48, minWidth: 48 }}>
          <Staff.Img.Thumb src="src" {...instance.cover_data} className="rounded" />
        </div>

        <div className="ms-1 d-grid grid-md-4 flex-1 gap-2">
          <div className="span-md-2">
            <div className="text-sm d-flex align-items-center">
              <PSTitle product={instance} className="me-1 pe-1" />
            </div>

            <div className="d-flex text-sm">
              <ProductPriceDisplay {...instance.export()} className="d-flex flex-wrap" />
              <div className="text-muted px-1">{`|`}</div>
              {supplier_item && supplier_item?.cost && (
                <div className="text-muted">{`${supplier_item?.cost} ${orderInstance?.currency || 'USD'}`}</div>
              )}
            </div>

            <div className="text-sm">
              {instance?.category_data?.name || ' - '}
              {supplier_item && <span className="text-muted">{` | ${supplier_item.category || '-'}`}</span>}
            </div>
          </div>

          <div className="text-sm text-left">
            <span>{`Sizes: ${instance.size_count}`}</span>
            {stageData && <span className="text-muted">{` - ${stageData.label}`}</span>}

            {supplier_item && (
              <a className="ms-1" href={supplier_item.url} target="_blank" rel="noopener noreferrer">{`↗️`}</a>
            )}
          </div>
        </div>
      </div>

      <div className="actions d-flex align-items-center">
        {instance.is_published && (
          <div className="d-none d-md-block me-1">
            <Button title="Copy link" className="btn-round btn-secondary-3">
              <Icons.Share />
            </Button>
          </div>
        )}

        <div className="d-none d-md-block">
          <ProductPinButton
            instance={instance}
            onSuccess={({ data }) => {
              props?.onUpdate?.(data);
            }}
          />
        </div>

        <ProductUpdateLink to={`./${to}`} className="btn btn-square btn-primary-3 ms-1">
          <Icons.Edit />
        </ProductUpdateLink>
      </div>
    </div>
  );
};

export const ProductCardItem = ({ data }: { data: TProduct }) => (
  <div className="p-card-list-item p-1 mb-1">
    <div className="d-flex align-items-center">
      <div className="p-name text-ellipsis">{data.name_truncated || data.name}</div>
      <div className="ms-1">
        <PublishedIcon show={data.is_published} />
      </div>
    </div>

    <div className="d-flex">
      <StaffImg.Square {...data?.cover_data} />

      <div className="p-info ps-1">
        <ProductPriceDisplay {...data} className="d-flex flex-wrap" />
        <div className="p-category">{data.category_data?.name}</div>
      </div>
    </div>
  </div>
);

export type ProductCardListProps = {
  product?: TProduct;
  items: TProduct[];
  itempath: string;
};

// TODO: MUST REFACTOR

export const ProductCardList = ({ items, product, itempath }: ProductCardListProps) => {
  const [params] = useSearchParams();
  const { prodSlug } = useParams();

  return (
    <div className="p-card-list-items">
      {items.map((item: TProduct) => {
        let to = `${itempath}${item.slug}/?${params}`;

        return (
          <NavLink to={to} key={item.slug} className={getCN([prodSlug === item.slug && 'active'])}>
            <ProductCardItem data={product && product.slug === item.slug ? product : item} />
          </NavLink>
        );
      })}
    </div>
  );
};
