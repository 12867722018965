import * as React from 'react';
import './viewports.scss';

import { useViewport } from '@miq/hookjs';
import { Loading } from '../Loaders';

export const Mobile: React.FC<any> = ({ children }) => {
  const { isMob } = useViewport();
  if (!isMob) return null;
  return <>{children}</>;
};

type ViewType =
  | 'XS'
  | 'SM'
  | 'SMDown'
  | 'SMUp'
  | 'MD'
  | 'MDDown'
  | 'MDUp'
  | 'LG'
  | 'LGDown'
  | 'LGUp'
  | 'XL'
  | 'XLDown'
  | 'XLUp'
  | 'XXL';

const withViewPort =
  (view: ViewType) =>
  ({ children, loading }: { children?: React.ReactNode; loading?: boolean }) => {
    const viewport = useViewport();

    if (!viewport[`is${view}`]) return null;
    if (loading) return <Loading />;

    return <>{children}</>;
  };

export const XS = withViewPort('XS');
export const SM = withViewPort('SM');
export const SMDown = withViewPort('SMDown');
export const SMUp = withViewPort('SMUp');
export const MD = withViewPort('MD');
export const MDDown = withViewPort('MDDown');
export const MDUp = withViewPort('MDUp');
export const LG = withViewPort('LG');
export const LGDown = withViewPort('LGDown');
export const LGUp = withViewPort('LGUp');
export const XL = withViewPort('XL');
export const XLDown = withViewPort('XLDown');
export const XLUp = withViewPort('XLUp');
export const XXL = withViewPort('XXL');
