import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import './views.scss';

import Staff from '@miq/staffjs';
import { Icons } from '@miq/componentjs';
import { useReloadSharedData } from '@miq/contextjs';

import SalesIndexView from './views.Index';
import { CartAddLink } from './cart/buttons';

const OrderRoutes = React.lazy(() => import('./order'));
const CartRoutes = React.lazy(() => import('./cart'));
const CustomerRoutes = React.lazy(() => import('./customer'));

export default function SalesStaffRoutes() {
  useReloadSharedData();

  return (
    <Staff.View title="Sales" actions={<CartAddLink />} className="sales-staff">
      <Staff.NavLinks
        navLinks={[
          { icon: <Icons.HomeDollar />, link: { to: './' } },
          { label: 'Paniers', icon: <Icons.BrandAppGallery />, link: { to: './carts/' } },
          { label: 'Clients', icon: <Icons.AddressBook />, link: { to: './customers/' } },
          { label: 'Commandes', icon: <Icons.Receipt />, link: { to: './orders/' } },
        ]}
      />

      <Routes>
        <Route path="orders/*" element={<OrderRoutes />} />
        <Route path="carts/*" element={<CartRoutes />} />
        <Route path="customers/*" element={<CustomerRoutes />} />

        <Route index element={<SalesIndexView />} />
      </Routes>
    </Staff.View>
  );
}

export { CustomerFields } from './customer';

export type { TCustomer } from './customer';
export type { TAPIOrderItem, TAPICart } from './cart';
export type { TAPIProduct } from './types';
