import * as React from 'react';

import { Icons, Img, SectionHeader } from '@miq/componentjs';
import { SharedDataCtx } from '@miq/contextjs';

import StaffView from './Views';
import { SidebarLink } from './components';

export default function DashboardView(props: { links?: any[] }) {
  const ctx = React.useContext(SharedDataCtx);
  const { user, apps } = ctx;
  const { links = [] } = props;

  return (
    <StaffView
      title="Dashboard"
      actions={
        <SidebarLink
          href="account/"
          label=""
          icon={<Img.Square {...user?.img_data} style={{ width: 32 }} className="round" />}
        />
      }
    >
      <SectionHeader title={`Welcome ${user?.first_name}`} text="It's lovely today" className="my-2" />

      <div className="d-grid grid-3 grid-md-4 gap-2">
        {links.map((i, v) => (
          <div className="text-center" key={v}>
            <SidebarLink {...i} />
          </div>
        ))}

        <div className="text-center">
          <SidebarLink href="settings/" label="Settings" icon={<Icons.Settings />} />
        </div>
      </div>

      <div className="d-grid grid-lg-5 gap-2"></div>
    </StaffView>
  );
}
