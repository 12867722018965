import * as React from 'react';

import { SharedDataCtx } from '@miq/contextjs';
import { getCN } from '@miq/utiljs';

const isArray = Array.isArray;

export const hasPerms = (perms: any, requiredPerms: string[]) => {
  if (!isArray(perms) || !isArray(requiredPerms)) return false;
  return perms.filter((perm) => requiredPerms.includes(perm)).length === requiredPerms.length;
};

type THasPerms = { requiredPerms?: string[]; fallback?: React.ReactNode };

export function withHasPerms<T extends object>(Component: React.ComponentType<T & THasPerms>, args?: any) {
  return function ({ requiredPerms, fallback, ...props }: T & THasPerms) {
    const { user, perms } = React.useContext(SharedDataCtx);
    if (!user || !user.is_staff || (requiredPerms && !hasPerms(perms.perms, requiredPerms))) {
      return fallback || args.fallback || null;
    }

    return <Component {...args} {...props} className={getCN([args?.className, props?.className])} />;
  };
}
