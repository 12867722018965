import * as React from 'react';

import { getCN } from '@miq/utiljs';

import { TViewHeaderProps, TViewSectionProps } from './types';

export const SectionHeader = (props: Omit<TViewHeaderProps, 'back' | 'children'>) => {
  const { header, title, text, actions } = props;

  const hasHeader = header || title || text || actions;
  if (!hasHeader) return null;

  const { prefix = 'miq-view-section', headerCN } = props;

  return (
    <div className={getCN([`${prefix}-header`, headerCN])}>
      {header ? (
        header
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <div className={`${prefix}-header-title`}>{title}</div>

          {actions && <section className={`${prefix}-header-actions`}>{actions}</section>}
        </div>
      )}

      {text && <div className={`${prefix}-header-text`}>{text}</div>}
    </div>
  );
};

export function ViewSection({ children, prefix = 'miq-view-section', border, ...props }: TViewSectionProps) {
  const { header, title, text, actions, headerCN, ...footerProps } = props;
  const { footer, footerCN, ...mainProps } = footerProps;
  const { mainCN, mainStyle, ...rest } = mainProps;

  return (
    <div {...rest} className={getCN([prefix, border && 'border', !!children && 'mb-1', rest.className])}>
      <SectionHeader {...{ prefix, header, title, text, actions, headerCN }} />

      {children && (
        <div className={`${getCN([`${prefix}-main`, mainCN])}`} style={mainStyle}>
          {children}
        </div>
      )}

      {footer && <div className={`${prefix}-footer`}>{footer}</div>}
    </div>
  );
}
