// import * as React from 'react';

import './staff.scss';

import Layout from './Layout';
import StaffView from './Views';
import { StaffImg } from './models';
import { StaffButton, StaffLink, StaffNavLink, StaffSection, StaffViewNavLinks } from './components';

export type StaffType = {};

const Staff = (args: any) => {
  return Object.assign({}, args);
};

Staff.Layout = Layout;

Staff.View = StaffView;
Staff.Section = StaffSection;

Staff.Button = StaffButton;
Staff.Link = StaffLink;
Staff.NavLink = StaffNavLink;
Staff.NavLinks = StaffViewNavLinks;

Staff.Img = StaffImg;

export default Staff;

export { default as DashboardView } from './views.Dashboard';
export { GridView } from './Views';
export { AnalyticsStaffRoutes } from './analytics';

export * from './analytics';
export { HitWindowSearchField } from './analytics/hits/components';

export * from './service';
export * from './models';
export * from './img';
