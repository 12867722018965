import { StaffModel, StaffService } from '@miq/staffjs';

export type TCustomer = Record<string, any> & {
  slug?: string;
  first_name?: string;
  last_name?: string;
  name?: string;
  email?: string;
  phone?: string;

  //

  orders_count?: number;
};

class CustomerService extends StaffService<TCustomer> {
  find(q: string) {
    return this.list({ params: { q } });
  }
}

export const Customer = (data: TCustomer = {}) => {
  return StaffModel<TCustomer, CustomerService>(data, 'customers', CustomerService);
};

export type TCustomerInstance = ReturnType<typeof Customer>;
