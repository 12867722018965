import axios from 'axios';

import { DOMAIN, CrudService } from '@miq/utiljs';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const API_ENDPOINT: string = `${DOMAIN}${process.env.REACT_APP_API_ENDPOINT}`;
export const STAFF_PATH: string = process.env.REACT_STAFF_PATH || '/staff/';

export const API = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 3000,
  withCredentials: true,
  headers: { 'Content-type': 'application/json' },
});

export class StaffService<T extends object> extends CrudService<T> {
  constructor(public data: T, endpoint: string) {
    super(data, endpoint, API);
  }
}

export function StaffModel<T extends object, V = StaffService<T>>(
  data: T,
  endpoint: string,
  TargetServiceClass: new (...args: any[]) => StaffService<T> = StaffService
) {
  const instance = new TargetServiceClass(data, endpoint);
  return instance as T & typeof instance & V;
}

export const getStaffModel =
  <T extends object = {}, V extends StaffService<T> = StaffService<T>>(
    endpoint: string,
    TargetServiceClass: new (data: T, endpoint: string) => V
  ) =>
  (data: T): V & T =>
    new TargetServiceClass(data, endpoint) as V & T;
