import React, { createContext, useState, useMemo } from 'react';

import { IS_DEV } from '@miq/utiljs';

export const FormCtx = createContext(null);

export const FormProvider = FormCtx.Provider;

export default FormCtx;

export interface useFormState {
  values: any;
  setValue: (key: string, val: any) => void;
  setValues: React.Dispatch<any>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: any;
  setError: (key: string, val: any) => void;
  setErrors: React.Dispatch<any>;
  handleError: (err: any, log?: boolean) => void;
  hasErrors: () => boolean;
}

export function useForm<T = any>(defaultValues: any = {}): useFormState {
  const [values, setValues] = useState<T>({ ...defaultValues });
  const [errors, setErrors] = useState<any>({});

  const context = useMemo(() => {
    const handleError = (err: any, log = true) => {
      if (!err || !err.response) {
        return;
      }

      const { response } = err;
      if (response.status !== 400) {
        IS_DEV && log && console.error(`Request error status: ${response.status}`);
        return err;
      }

      let newData = {};
      const { data } = response;

      if (IS_DEV && log) {
        console.error('ERROR=>', data);
      }

      Object.keys(data).forEach((key) => {
        let e = data[key];
        if (Array.isArray(e) && e.length > 0) {
          e = e[0];
        }
        newData = { ...newData, [key]: e.message || e };
      });

      setErrors({ ...newData });
    };

    const setError = (key: string, value: any) => {
      setErrors({ ...errors, [key]: value });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, type, value } = e.target;

      if (Object.keys(errors).includes(name)) {
        setErrors({ ...errors, [name]: null });
      }

      switch (type) {
        case 'checkbox':
          return toggleCheck(e);

        default:
          return setValue(name, value);
      }
    };

    const setValue = (key: string, value: any) => {
      setValues({ ...values, [key]: value });
    };

    const toggleCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = target;
      return setValue(name, checked);
    };

    return {
      values,
      setValue,
      setValues,
      handleChange,
      toggleCheck,
      errors,
      setError,
      setErrors,
      handleError,
      hasErrors: () => Object.keys(errors).join() !== '',
    };
  }, [values, errors]);

  return context;
}

export type TFormCtx = ReturnType<typeof useForm>;
