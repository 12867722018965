import * as React from 'react';

import './layouts.scss';

import { getCN } from '@miq/utiljs';
import { TViewProps, View } from '../Views';

/** BASE LAYOUT */

export interface IBaseLayoutProps extends React.ComponentPropsWithoutRef<'div'> {
  fallback?: JSX.Element;
}

export const BaseLayout: React.FC<IBaseLayoutProps> = (props) => {
  const { className, ...rest } = props;
  return <div {...rest} className={getCN(['miq-layout', className])} />;
};

export interface IStickyFooterLayoutProps extends Omit<IBaseLayoutProps, 'title'>, TViewProps {}

export const StickyFooterLayout: React.FC<IStickyFooterLayoutProps> = ({ id, className, ...viewProps }) => {
  const { mainCN, headerCN, footerCN, style, ...props } = viewProps;

  return (
    <BaseLayout className={getCN(['miq-sticky-footer-layout', className])} style={style}>
      <View
        {...props}
        className="miq-sticky-footer-view"
        mainCN={getCN([mainCN, 'miq-sticky-footer-main'])}
        headerCN={getCN([headerCN, 'miq-sticky-footer-header'])}
        footerCN={getCN([footerCN, 'miq-sticky-footer-footer'])}
      />
    </BaseLayout>
  );
};

export interface IHorizontalScrollGridLayoutProps extends React.ComponentPropsWithoutRef<'div'> {}

export const HorizontalScrollGridLayout = ({ children, ...props }: IHorizontalScrollGridLayoutProps) => {
  const { className, ...rest } = props;
  return (
    <div {...rest} className={getCN(['miq-hgrid-layout', className])}>
      {children}
    </div>
  );
};
