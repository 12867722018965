import * as Axios from 'axios';

import { Img, TImg } from '@miq/componentjs';
import { StaffModel, StaffService } from './service';

export type TUser = {
  first_name?: string;
  last_name?: string;
  gender?: string;
  img?: string;
  img_data?: TImg;
  employee?: any;
};

export class StaffUserService extends StaffService<TUser> {}

export const StaffUser = (data: TUser = {}) => StaffModel<TUser, StaffUserService>(data, 'account/', StaffUserService);

export type TStaffUserInstance = ReturnType<typeof StaffUser>;

//
//
//

class StaffImgService extends StaffService<TImg> {
  swapPosition(slug: string, position?: number) {
    if (!this.isBound) return Promise.reject('Not bound');
    return this.patch(`${this._path}swap_position/`, { slug, position });
  }
  createFromUrl(url: string, alt_text: string = '') {
    return super.create({ src: url, alt_text });
  }

  // @ts-ignore
  create(
    file: File,
    alt_text?: string,
    config: Axios.AxiosRequestConfig<any> = {}
  ): Promise<Axios.AxiosResponse<any, any>> {
    if (file.name.length > 499) {
      return Promise.reject('Filename too long');
    }
    const fd = new FormData();
    fd.append('src', file, file.name);
    if (alt_text) fd.append('alt_text', alt_text);

    return super.create(fd, { ...config, headers: { 'content-type': 'multipart/form-data' } });
  }

  updateMobile(file: File) {
    return this.update(file, undefined, 'mobile');
  }
  updateSquare(file: File) {
    return this.update(file, undefined, 'square');
  }
  updateThumb(file: File) {
    const { name } = file;
    return this.update(file, undefined, 'thumb');
  }
  updateValues(values: any) {
    return super.update(values);
  }

  // @ts-ignore
  update(file: File, alt_text?: string, field?: string): Axios.AxiosPromise {
    if (file.name.length > 499) {
      return Promise.reject('Filename too long');
    }

    const fd = new FormData();

    if (!field) field = 'src';
    switch (field) {
      case 'mobile':
        fd.append('src_mobile', file, file.name);
        break;
      case 'thumb':
        fd.append('thumb', file, file.name);
        break;
      case 'square':
        fd.append('thumb_sq', file, file.name);
        break;
      default:
        fd.append('src', file, file.name);
        break;
    }

    if (alt_text) fd.append('alt_text', alt_text);

    return super.update(fd, { headers: { 'content-type': 'multipart/form-data' } });
  }
}

export const StaffImg = (data: TImg = {}) => {
  // @ts-ignore
  return StaffModel<TImg, StaffImgService>(data, 'staffimages', StaffImgService);
};

export type TSImgInstance = ReturnType<typeof StaffImg>;

StaffImg.HorizontalGallery = Img.HorizontalGallery;
StaffImg.VerticalGallery = Img.VerticalGallery;
StaffImg.Mobile = Img.Mobile;
StaffImg.Thumb = Img.Thumb;
StaffImg.Square = Img.Square;
StaffImg.Picture = Img.Picture;
StaffImg.Image = Img.Image;
