import * as React from 'react';

type TClickToEditProps<TEditProps = {}> = Exclude<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'onClick'
> & {
  children: React.ReactNode;
  editView: (
    props: TEditProps & { isEdit: boolean; setEdit: React.Dispatch<React.SetStateAction<boolean>> }
  ) => JSX.Element;
};

export const ClickToEdit = ({ children, ...props }: TClickToEditProps) => {
  const [isEdit, setEdit] = React.useState<boolean>(false);
  const { editView: EditView, ...rest } = props;

  if (isEdit) return <EditView isEdit={isEdit} setEdit={setEdit} />;

  return (
    <div {...rest} onClick={() => setEdit(!isEdit)}>
      {children}
    </div>
  );
};

export const DoubleClickToEdit = ({ children, ...props }: Exclude<TClickToEditProps, 'onClick' | 'onDoubleClick'>) => {
  const [isEdit, setEdit] = React.useState<boolean>(false);
  const { editView: EditView, ...rest } = props;

  if (isEdit) return <EditView isEdit={isEdit} setEdit={setEdit} />;

  return (
    <div {...rest} onDoubleClick={() => setEdit(!isEdit)}>
      {children}
    </div>
  );
};
