import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

const HitListView = React.lazy(() => import('./views.List'));

export default function HitRoutes() {
  return (
    <Routes>
      <Route index element={<HitListView />} />
    </Routes>
  );
}

export { useHitListRequest, Hit } from './utils';
export { HitListItem } from './views.List';
export type { THitInstance, THit } from './utils';
