import * as React from 'react';
import { LinkProps } from 'react-router-dom';

import { TCustomer, TCustomerInstance } from './utils';
import Staff from '@miq/staffjs';
import { getCN } from '@miq/utiljs';

type TCustomerOrInstance = TCustomer | TCustomerInstance;

export const CustomerUpdateLink = ({ data, ...props }: { data: TCustomerOrInstance } & Omit<LinkProps, 'to'>) => (
  <Staff.Link
    {...props}
    to={`/staff/sales/customers/${data?.slug}/`}
    requiredPerms={['sales.change_customer']}
    className={getCN(['customer-update-link', props.className])}
    title="Update customer"
  />
);
