import { useRequest } from '@miq/hookjs';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { StaffService, StaffModel } from '../../service';

export type THit = {
  slug?: string | null;
  url: string;
  path: string;
  source_id?: string;
  app?: string;
  model?: string;
  ip?: string;
  session?: string;
  referrer?: string;
  user_agent?: string;
  method?: string;
  response_status?: number;
  debug?: boolean;
  session_data?: Record<string, any>;
  parsed_data?: Record<string, any>;
  customer_data?: Record<string, any>;
  created?: string;
  updated?: string;
};

export const useHitListRequest = (defParams?: URLSearchParamsInit) => {
  const [params] = useSearchParams(defParams);

  const __public = params.get('__public');
  const __starts_with = params.get('__starts_with');
  const __q = params.get('__q');
  const __path = params.get('__path');
  const __url = params.get('__url');
  const __ref = params.get('__ref');
  const __ip = params.get('__ip');
  const __bot = params.get('__bot');
  const __ua = params.get('__ua');
  const __status = params.get('__status');
  const __dt = params.get('__dt');
  const page = params.get('page');

  return useRequest<THit>(() => Hit({}).list({ params }), {
    refreshDeps: [__q, __path, __url, __ref, __ip, __bot, __ua, __status, __dt, __public, __starts_with, page],
  });
};

class HitService extends StaffService<THit> {
  get img() {
    return this.data?.session_data?.img;
  }

  deleteSlugs(slugs: string[]) {
    return this.post(`${this._endpoint}batch/`, { slugs, action: 'destroy' });
  }

  getSummary(params?: URLSearchParams) {
    return this.get(`${this._endpoint}summary/`, { params });
  }
}

export const Hit = (data: THit = {}) => {
  return StaffModel<THit, HitService>(data, 'hits', HitService);
};

export type THitInstance = ReturnType<typeof Hit>;
