import * as React from 'react';

import { ResultsResponse } from '@miq/hookjs';
import Form, { EmailField, FirstNameField, TextFieldProps } from '@miq/formjs';

import { Customer, TCustomer } from '../utils';

const CustomerSelect = ({ customers, onItemClick }: { customers: TCustomer[]; onItemClick: (p) => void }) => {
  return (
    <div className="mt-1 border-1 rounded bg-white">
      {customers.map((cust) => {
        return (
          <div
            className="d-flex justify-content-between p-1 border-bottom"
            onClick={() => onItemClick(cust)}
            key={cust?.slug}
          >
            <div className="fw-bold">{`${cust.first_name} ${cust.last_name}`}</div>
            <div className="text-muted text-sm">{cust.phone}</div>
          </div>
        );
      })}
    </div>
  );
};

export const CustomerLNameField = ({
  label = 'Nom',
  text,
  error,
  fieldCN,
  ...props
}: TextFieldProps & { allowSuggestions?: boolean; onCustomerSelect?: (p: TCustomer) => void }) => {
  const [customers, setCustomers] = React.useState<TCustomer[]>([]);

  const handleDebounce = ({ value }) => {
    if (!allowSuggestions || !value || value.length < 3) return;

    Customer()
      .find(value)
      .then((r) => {
        const items = new ResultsResponse(r).items;

        if (items.length > 0) setCustomers(items);
        else setCustomers([]);
      })
      .catch((err) => {});
  };

  const { onCustomerSelect, allowSuggestions, ...rest } = props;

  return (
    <div className="">
      <Form.Field {...{ label, error, text }} className={fieldCN}>
        <div className="span-md-3">
          <Form.Text required name="last_name" {...rest} maxLength={99} minLength={2} onDebounce={handleDebounce} />

          {allowSuggestions && Boolean(customers.length) && (
            <CustomerSelect
              customers={customers}
              onItemClick={(p) => {
                onCustomerSelect?.(p);
              }}
            />
          )}
        </div>
      </Form.Field>
    </div>
  );
};

export const CustomerFNameField = (props: TextFieldProps) => <FirstNameField required {...props} />;
export const CustomerEmailField = (props: TextFieldProps) => <EmailField {...props} />;

export const CustomerPhoneField = ({
  label = 'Numéro de téléphone',
  text,
  error,
  fieldCN,
  ...props
}: TextFieldProps) => (
  <Form.Field {...{ label, error, text }} className={fieldCN}>
    <Form.Phone required name="phone" {...props} />
  </Form.Field>
);
