import * as React from 'react';

import Staff from '@miq/staffjs';
import { useDocumentTitle, ResultsResponse } from '@miq/hookjs';
import { getCN } from '@miq/utiljs';
import { HView, Loading, Pagination } from '@miq/componentjs';
import { THit, useHitListRequest } from './utils';
import {
  HitCart,
  HitDate,
  HitImg,
  HitPath,
  HitQueries,
  HitReferrer,
  HitStatus,
  HitUA,
  HitUrl,
  HitUser,
} from './components';
import { StaffViewNavLinks } from '../../components';

const navLinks = [
  { label: 'Views', link: { to: '.' } },
  { label: 'Bots', link: { to: './?__bot=1' } },
  { label: 'Errors', link: { to: './?__err=1' } },
  // { label: 'Bots', link: { to: './?bot=1' } },
];

export default function HitListView() {
  useDocumentTitle('Hits - Analytics');

  const listCtx = HView.useView();
  const { res, loading } = useHitListRequest({ __public: '1' });

  if (loading) return <Loading />;

  const r = new ResultsResponse<THit>(res);
  if (!r.isSuccess) return null;

  return (
    <HView context={listCtx}>
      <Staff.View
        title={
          <>
            <StaffViewNavLinks navLinks={navLinks} />
          </>
        }
        footer={<Pagination {...r.pagination} component={Staff.Link} to />}
      >
        <HView.List
          className="text-sm"
          itemCN="border-1 rounded p-1 mb-1"
          items={r.items}
          renderItem={({ data }) => {
            return <HitListItem hit={data} />;
          }}
        />
      </Staff.View>
    </HView>
  );
}

export const HitListItem = ({ hit, ...props }: { hit: THit; className?: string }) => {
  return (
    <div className={getCN(['d-flex', props?.className])}>
      <div style={{ width: 48, minWidth: 48 }}>
        <HitImg {...{ hit }} />
      </div>

      <div className="d-grid ms-1">
        <div className="d-flex flex-wrap">
          <HitUser search {...{ hit }} className="me-1" />
          <HitCart {...{ hit }} className="me-1 bg-green-100" />
          {` | `}
          <HitDate time {...{ hit }} className="me-1" />
        </div>

        <div className="d-grid grid-md-12 gap-1">
          <HitStatus search {...{ hit }} className="me-1" />

          <div className="span-md-10 text-ellipsis">
            <HitPath search {...{ hit }} className="me-1" />
          </div>

          <HitUrl search {...{ hit }} className="bg-white color-blue-400">
            {` « url » `}
          </HitUrl>
        </div>

        <div className="text-muted" title={hit.user_agent}>
          <HitUA search {...{ hit }} className="me-1" />
          <HitReferrer search {...{ hit }} />
        </div>

        <div className="">
          <HitQueries hit={hit} />
        </div>
      </div>
    </div>
  );
};
