import { TLang } from '@miq/utiljs';

export default {
  //   'NField.placeholder': { fr: "", en: "" },

  /**
   * ************************ FIELDS
   */

  //
  'PresaleField.label': { fr: 'En pré-vente', en: 'Pre sale' },
  'PresaleTextField.label': { fr: 'Pré-vente description', en: 'Pre sale text' },
  'PresaleTextField.text': {
    fr: 'Ajouter un texte de pré-vente. (facultatif)',
    en: 'Add a pre sale text (not required)',
  },
  'PresaleTextField.placeholder': { fr: 'Expliquez ... (facultatif)', en: 'Explain ... (not required)' },

  //
  'OnsaleField.label': { fr: 'En solde', en: 'On sale' },

  //
  'OosField.label': { fr: 'En rupture de stock', en: 'Out of Stock' },

  //
  'CatField.label': { fr: 'Catégorie', en: 'Category' },
  'CatField.nullValueLabel': { fr: 'Sélectionnez une catégorie ...', en: 'Select a category ...' },

  //
  'RetailPField.label': { fr: 'Prix', en: 'Price' },
  'RetailPField.placeholder': { fr: 'Combien coûte le produit', en: 'Enter the price' },

  //
  'SalePField.label': { fr: 'Prix réduit', en: 'Sale price' },
  'SalePField.placeholder': { fr: 'Prix réduit', en: 'Discounted price' },

  //
  'NField.label': { fr: 'Nom', en: 'Name' },
  'NField.text': {
    fr: `Ajoutez un nom qui décrit ce produit. Les noms peuvent contenir jusqu’à 99 caractères.\nNous recommandons 65 caractères au max`,
    en: 'Add a name that accurately describes the product. Names can contain up to 99 characters.\nThough we recommend 65 characters',
  },
  'NField.placeholder': { fr: 'Entrez un nom clair et concis ...', en: "Enter product's name ..." },

  //
  'DField.label': { fr: 'Description', en: 'Description' },
  'DField.text': { fr: `Ajoutez les caractéristiques uniques qui décrivent ce produit`, en: '' },
  'DField.placeholder': { fr: 'Décrivez les caractéristiques et les avantages ...', en: 'Describe product ...' },

  //
  'StageField.nullValueLabel': { fr: 'Sélectionnez un stage ...', en: 'Select stage ...' },

  //
  'ExplicitField.label': { fr: 'Exclure de la boutique Facebook', en: 'Exclude from facebook Shop' },

  /**
   * ************************ FORMS
   */

  'CreateForm.submitLabel': { fr: 'Suivant', en: 'Continue' },
  //
  'QUpdateForm.removeFromOrderLabel': { fr: 'Retirer de la commande', en: 'Remove from order' },
  'QUpdateForm.submitLabel': { fr: 'Sauvegarder', en: 'Update' },

  //
  'ListFilterForm.publishedOptionLabel': { fr: 'Produits publiés', en: 'Published' },
  'ListFilterForm.pinnedOptionLabel': { fr: 'Produits épinglés', en: 'Pinned' },
  'ListFilterForm.unpublishedOptionLabel': { fr: 'Produits non publiés', en: 'Unpublished' },
  'ListFilterForm.explicitOptionLabel': { fr: 'Produits exclus de Facebook', en: 'Excluded from FB Shop' },
  'ListFilterForm.publishedEmptyLabel': { fr: 'Tous les produits ...', en: 'All products ...' },

  'ListFilterForm.preSaleLabel': { fr: 'En pré-vente', en: 'Pre sale' },
  'ListFilterForm.onSaleLabel': { fr: 'En promo', en: 'On sale' },

  'ListFilterForm.categoryEmptyLabel': { fr: 'Toutes les catégories ...', en: 'All categories ...' },
  'ListFilterForm.noCategoryOptionLabel': { fr: 'Sans catégorie', en: 'No category' },
  'ListFilterForm.Q.placeholder': {
    fr: 'Cherchez un produit par nom, catégorie ou numero de serie ...',
    en: 'Search products by name, category or serial number ...',
  },
  'ListFilterForm.submitLabel': { fr: 'Rechercher', en: 'Search' },

  //
} as TLang;
