import * as React from 'react';

import { Button, TButtonProps, ConfirmButton, Icons } from '@miq/componentjs';
import Form, { FormFieldProps, TFormCallbackProps, SelectFieldProps, TextFieldProps, useForm } from '@miq/formjs';

import { StaffProduct } from '../product/models';
import type { TProductSize } from '../types';

type TUpdateFormProps<T extends {} = {}> = TFormCallbackProps & {
  productInstance: ReturnType<typeof StaffProduct>;
} & T;

export const SizeUpdateForm = ({ data, productInstance, ...props }: TUpdateFormProps<{ data: TProductSize }>) => {
  const form = useForm<TProductSize>({
    name: data.name || '',
    code: data.code || '',
    quantity: data.quantity || 0,
  });

  if (!data?.slug || !productInstance?.slug) return null;

  const { onSuccess, onFailure } = props;

  return (
    <Form
      context={form}
      onSubmit={(e) => {
        e.preventDefault();

        productInstance
          .patchSize(data.slug!, form.values)
          .then((res) => onSuccess?.(res))
          .catch((err) => {
            form.handleError(err);
            return onFailure?.(err);
          });
      }}
    >
      <div className="d-grid grid-5 gap-1">
        <SizeNameField error={form.errors.name} />
        <SizeCodeField error={form.errors.code} />
        <SizeQuantityField error={form.errors.quantity} />

        <div className="span-md-2">
          <Form.Submit value="Save" className="btn-primary-2 me-2" />
          <SizeDeleteButton {...{ productInstance, data, onSuccess, onFailure }} />
        </div>
      </div>
    </Form>
  );
};

const commonSizes: { [k: string]: any } = {
  xs: { name: 'Extra-small', code: 'XS' },
  s: { name: 'Small', code: 'S' },
  m: { name: 'Medium', code: 'M' },
  l: { name: 'Large', code: 'L' },
  xl: { name: 'Extra-large', code: 'XL' },
};
const formDefaultValues: TProductSize = { name: '', code: '', quantity: 1 };

export const SizeAddForm = ({ productInstance, ...props }: TUpdateFormProps) => {
  const form = useForm<TProductSize>(formDefaultValues);

  if (!productInstance?.slug) return null;

  return (
    <Form
      context={form}
      onSubmit={(e) => {
        e.preventDefault();

        productInstance
          .postSize(form.values)
          .then((res) => {
            form.setValues(formDefaultValues);
            return props?.onSuccess?.(res);
          })
          .catch((err) => {
            form.handleError(err);
            return props?.onFailure?.(err);
          });
      }}
    >
      <div className="d-flex my-2">
        <Button
          onClick={() => form.setValues({ ...form.values, name: 'One-size', code: 'ONESIZE' })}
          className="size-select-btn me-1"
        >
          One/size
        </Button>

        {Object.keys(commonSizes).map((key: string) => {
          const data = commonSizes[key];
          return (
            <Button
              onClick={() => form.setValues({ ...form.values, ...data })}
              className="size-select-btn me-1"
              key={key}
            >
              {key}
            </Button>
          );
        })}
      </div>
      <div className="d-grid grid-5 gap-1">
        <SizeNameField error={form.errors.name} />
        <SizeCodeField error={form.errors.code} />
        <SizeQuantityField error={form.errors.quantity} />

        <div className="span-md-2">
          <Form.Submit value="Save" className="btn-primary-2 me-2" />
        </div>
      </div>
    </Form>
  );
};

const SizeNameField = (props: FormFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Text
        placeholder="Entrez le nom de la taille ..."
        {...rest}
        required
        name="name"
        maxLength={20}
        minLength={2}
      />
    </Form.Field>
  );
};
const SizeCodeField = (props: FormFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Text placeholder="Entrez le code de la taille ..." {...rest} required name="code" maxLength={10} />
    </Form.Field>
  );
};

export const SizeQuantityField = (props: TextFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Text placeholder="Entrez la quantité ..." {...rest} required type="number" name="quantity" min={0} />
    </Form.Field>
  );
};

export const SizeSelectField = (props: SelectFieldProps & { sizes: TProductSize[] }) => {
  const { label, text, error, sizes, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Select {...rest} required name="size" nullValue={{ label: 'Sélectionnez une taille ...' }}>
        {sizes?.map((size) => {
          return <Form.Option label={size.code?.toUpperCase()} value={size.slug!} key={size.slug} />;
        })}
      </Form.Select>
    </Form.Field>
  );
};

const SizeSkuField = (props: FormFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Text placeholder="Sku" {...rest} name="sku" maxLength={99} minLength={2} />
    </Form.Field>
  );
};

const SizeDeleteButton = ({
  data,
  productInstance,
  ...props
}: TUpdateFormProps & { data: TProductSize } & Omit<TButtonProps, 'children' | 'onError'>) => {
  if (!data?.slug || !productInstance?.slug) return null;
  const { onSuccess, onFailure, ...rest } = props;

  return (
    <ConfirmButton
      {...rest}
      renderHeader={() => <div>Supprimer une taille</div>}
      renderFooter={({ setOpen }) => (
        <div className="d-flex justify-content-between align-items-center">
          <Button onClick={() => setOpen(false)}>Annuler</Button>

          <Button
            onClick={() =>
              productInstance
                .deleteSize(data.slug!)
                .then((res) => {
                  setOpen(false);
                  return onSuccess?.(res);
                })
                .catch((err) => onFailure?.(err))
            }
            className="btn-danger"
          >
            Supprimer
          </Button>
        </div>
      )}
      render={() => (
        <div className="p-3">
          Are you sure you want to delete this size:
          <div className="fw-bold">{data.name}</div>
        </div>
      )}
      className="size-delete-button btn-danger-3"
    >
      <Icons.Trash />
    </ConfirmButton>
  );
};
