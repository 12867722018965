import * as React from 'react';

import './view.scss';

import { withHasPerms } from './utils';
import { TViewProps, View } from '@miq/componentjs';
import { getCN, throttle } from '@miq/utiljs';

const StaffView = withHasPerms<TViewProps>(View, { prefix: 'miq-staff-view' });

export default StaffView;

type TGridViewProps = React.ComponentPropsWithoutRef<'div'> & {
  aside?: React.ReactNode;
  asideCN?: string;
  mainCN?: string;
  scroll?: boolean;
};

export const GridView = ({ children, className, ...props }: TGridViewProps) => {
  const colRef = React.useRef(null);
  const [rect, setRect] = React.useState({});

  React.useEffect(() => {
    if (typeof window === 'undefined' || typeof window !== 'object') return;

    const el: any = colRef.current;
    if (!el) return;

    const resize = throttle(
      () => {
        setRect({
          rect: el.getBoundingClientRect(),
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
            pixelRatio: window.devicePixelRatio,
          },
        });
      },
      400,
      { trailing: true }
    );

    window.addEventListener('resize', resize);
    resize();

    return () => window.removeEventListener('resize', resize);
  }, []);

  const { aside, asideCN, mainCN, scroll, ...rest } = props;

  const height = Math.abs(rect?.rect?.height - rect?.window?.height) || undefined;
  // console.log(height);

  return (
    <div style={{ position: 'relative' }} ref={colRef}>
      <div {...rest} className={getCN(['miq-grid-view', className, scroll && 'scrollable'])}>
        {aside && <div className={getCN(['miq-grid-view-aside', asideCN])}>{aside}</div>}
        <div className={getCN(['miq-grid-view-main', mainCN])} style={{ height }}>
          {children}
        </div>
      </div>
    </div>
  );
};
