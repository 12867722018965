import * as React from 'react';
import lang from './lang';

import { DescriptionField as DField, NameField as NField } from '@miq/formjs';
import Form, { CheckboxFieldProps, SelectFieldProps, TextChoicesType, TextFieldProps } from '@miq/formjs';

import { CategoryChoicesType } from '../../types';

const { i18n } = window;

export const NameField = (props: TextFieldProps) => (
  // maxLenght - recommended for facebook and google
  <NField placeholder={i18n(lang, 'NField.placeholder')} {...props} maxLength={60} />
);

export const DescriptionField = (props: TextFieldProps) => (
  <DField placeholder={i18n(lang, 'DField.placeholder')} {...props} />
);

export const CategoryField = (props: SelectFieldProps & { categories: CategoryChoicesType }) => {
  const { label, text, error, categories, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Select {...rest} required name="category" nullValue={{ label: i18n(lang, 'CatField.nullValueLabel') }}>
        {categories?.items?.map((cat) => {
          return <Form.Option {...cat} key={cat.value} />;
        })}
      </Form.Select>
    </Form.Field>
  );
};

export const SalePriceField = (props: TextFieldProps) => {
  const { label, text, error, ...rest } = props;
  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Text
        aria-describedby="sale_price_label"
        min="0"
        step="0.01"
        required
        placeholder={i18n(lang, 'SalePField.placeholder')}
        {...rest}
        type="number"
        name="sale_price"
      />
    </Form.Field>
  );
};

export const OnSaleCheckboxField = (props: CheckboxFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }} checkbox>
      <Form.Checkbox {...rest} name="is_on_sale" />
    </Form.Field>
  );
};

export const PresaleCheckboxField = (props: CheckboxFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }} checkbox>
      <Form.Checkbox {...rest} name="is_pre_sale" />
    </Form.Field>
  );
};

export const OosCheckboxField = (props: CheckboxFieldProps) => {
  const { label = i18n(lang, 'OosField.label'), text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }} checkbox>
      <Form.Checkbox {...rest} name="is_oos" />
    </Form.Field>
  );
};

export const PresaleTextField = (props: TextFieldProps) => {
  const { label, text, error, ...rest } = props;
  return (
    <Form.Field {...{ label, text, error }}>
      <Form.TextAreaX placeholder="" {...rest} name="is_pre_sale_text" aria-describedby="is_pre_sale_text_label" />
    </Form.Field>
  );
};

export const RetailPriceField = (props: TextFieldProps) => {
  const { label, text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Text
        required
        placeholder={i18n(lang, 'RetailPField.placeholder')}
        aria-describedby="retail_price_label"
        min="0"
        step="0.01"
        {...rest}
        type="number"
        name="retail_price"
      />
    </Form.Field>
  );
};

export type TProductStageSelectFieldProps = SelectFieldProps & { stages: TextChoicesType };

export const StageSelectField = (props: TProductStageSelectFieldProps) => {
  const { label, text, error, required = true, stages, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }}>
      <Form.Select
        required={required}
        name="stage"
        nullValue={{ label: i18n(lang, 'StageField.nullValueLabel') }}
        {...rest}
      >
        {stages?.map?.((stage) => {
          return <Form.Option {...stage} key={stage.value} />;
        })}
      </Form.Select>
    </Form.Field>
  );
};

export const IsExplicitCheckboxField = (props: CheckboxFieldProps) => {
  const { label = i18n(lang, 'ExplicitField.label'), text, error, ...rest } = props;

  return (
    <Form.Field {...{ label, text, error }} checkbox>
      <Form.Checkbox {...rest} name="is_explicit" />
    </Form.Field>
  );
};
