import * as React from 'react';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';

import { getCN, uuid } from '@miq/utiljs';

import { TViewHeaderProps, TViewProps } from './types';
import { Settings } from '../Icons/tabler.icons';

export const ViewHeaderBackLink = (props: NavLinkProps & { prefix: TViewHeaderProps['prefix'] }) => (
  <Link
    {...props}
    className={`${props?.prefix}-header-back btn btn-round me-1`}
    title={props.title ?? 'Go back'}
    style={{ width: 30, height: 30, borderRadius: '50%', ...props?.style }}
  >
    {`«`}
  </Link>
);

export const ViewHeader = ({ back, header, title, text, actions, headerCN, ...props }: TViewHeaderProps) => {
  const hasHeader = header || title || text || actions || back;
  if (!hasHeader) return null;

  const { prefix = 'miq-view' } = props;

  return (
    <header className={getCN([`${prefix}-header-wrapper`, headerCN])}>
      {header ? (
        header
      ) : (
        <>
          <div className={`${prefix}-header d-flex`}>
            <div className="d-flex align-items-center">
              {back && <ViewHeaderBackLink to={back} prefix={prefix} />}

              <div className={`${prefix}-header-title`}>
                {typeof title === 'string' ? <div className={`${prefix}-header-title-text`}>{title}</div> : title}
              </div>
            </div>

            {actions && <section className={`${prefix}-header-actions`}>{actions}</section>}
          </div>

          {text && <div className={`${prefix}-header-text`}>{text}</div>}
        </>
      )}
    </header>
  );
};

export function View({ children, loading, prefix = 'miq-view', ...props }: TViewProps) {
  const { header, title, text, actions, back, headerCN, ...footerProps } = props;
  const { footer, footerCN, ...mainProps } = footerProps;
  const { mainCN, mainStyle, ...rest } = mainProps;

  return (
    <div {...rest} className={getCN([prefix, props.className])}>
      <ViewHeader {...{ prefix, header, title, text, actions, back, headerCN }} />

      {children && (
        <div className={getCN([`${prefix}-main`, mainCN])} style={mainStyle}>
          {children}
        </div>
      )}

      {footer && <footer className={getCN([`${prefix}-footer`, footerCN])}>{footer}</footer>}
    </div>
  );
}

type TViewNavLinkProps = {
  link: Omit<NavLinkProps, 'children'>;
  label?: string;
  icon?: ReturnType<typeof Settings>;
  showLabel?: boolean | string;
};

export type TViewNavLinksProps = { navLinks?: TViewNavLinkProps[] };

View.NavLinks = ({ navLinks = [] }: TViewNavLinksProps) => {
  return (
    <div className="view-navlinks">
      {navLinks.map(({ label, icon, link, ...rest }) => {
        return (
          <NavLink {...link} key={uuid()} title={link?.title || label} className="view-navlink">
            {icon}
            {label && <span className="view-navlink-label">{label}</span>}
          </NavLink>
        );
      })}
    </div>
  );
};

// const TabLinks = (props: TNav) => {
//   return (
//     <div className="view-nav">
//       <Link to="./" className={getCN([!tab && 'active'])}>
//         Site Details
//       </Link>
//       <Link to="./?tab=contact" className={getCN([tab === 'contact' && 'active'])}>
//         Contact
//       </Link>

//       <Link to="./?tab=analytics" className={getCN([tab === 'analytics' && 'active'])}>
//         Analytics
//       </Link>
//       <Link to="./?tab=social" className={getCN([tab === 'social' && 'active'])}>
//         Social
//       </Link>
//       <Link to="./?tab=apps" className={getCN([tab === 'apps' && 'active'])}>
//         Apps
//       </Link>
//     </div>
//   );
// };
