import * as React from 'react';

import './views.scss';

import Form from '@miq/formjs';
import { getCN } from '@miq/utiljs';
import type { TAPICart, TAPIProduct } from '@shopy/salejs';
import { SharedDataCtx, TSDState } from '@miq/contextjs';
import { Icons, IPaginationProps, TViewProps, Pagination, View } from '@miq/componentjs';

import { OrderItemForm } from './forms';

import {
  PresaleStarIcon,
  ProductAttributeList,
  ProductGridProps,
  ProductImagesHorizontalGallery,
  ProductPrice,
} from './components';

export interface IProductDetailViewProps extends TViewProps {
  header?: React.ReactNode;
  images?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  readonly clsPrefix?: string;
}

const presaleText = 'Ce produit sera disponible dans un mois.';
// REDIRECT TO WHATSAPP
const successPath = `./?r=1`;

export const ProductDetailView = (props: IProductDetailViewProps) => {
  const ctx = React.useContext<TSDState<{ product: TAPIProduct; cart?: TAPICart }>>(SharedDataCtx);
  const { cart, product, updateKey } = ctx;

  const { clsPrefix = 'miq-shop-product-detail-view', images, children, body, ...rest } = props;
  const { is_oos, is_pre_sale } = product;
  const is_pre_sale_text = product?.is_pre_sale_text || presaleText;

  const handleSuccess = (p: TAPICart) => {
    updateKey('cart', p);
    window.location.href = successPath;
  };

  return (
    <View {...rest} className={getCN([clsPrefix, props.className])}>
      <div className={`${clsPrefix}-grid d-grid grid-md-12`}>
        {/* media */}
        <>
          <div className={`${clsPrefix}-grid-media span-md-8`}>{images}</div>
        </>

        {/* product */}
        <>
          <div className={`${clsPrefix}-grid-product span-md-4 p-1`}>
            {is_oos ? (
              <div className="my-2">
                <span className="bg-red-100 color-red-600  px-1 rounded">En rupture de stock</span>
              </div>
            ) : is_pre_sale ? (
              <div className="my-2">
                <span className="presale-icon">
                  <PresaleStarIcon />
                  <span className="px-2 color-orange-600 bg-yellow-100 rounded">Nouvel Arrivage</span>
                </span>
              </div>
            ) : null}

            <>
              <h1 className="p-name fw-lighter">{product.name}</h1>
            </>

            <>
              <ProductPrice {...product} />
            </>

            <>{/* <div className="mb-3"><ProductPreSaleDate product={product} /></div> */}</>

            <div className="text-muted mb-2">
              {is_oos ? (
                <>
                  <p>Ce produit n'est plus disponible.</p>
                </>
              ) : is_pre_sale && is_pre_sale_text ? (
                <>
                  <div className="p-presale-text">{is_pre_sale_text}</div>
                  <p>Pour réserver, choisissez votre taille et envoyez-nous un message sur whatsapp.</p>
                </>
              ) : (
                <>
                  <div className="sale-text">Ce produit est disponible immédiatement.</div>
                  <p>Pour commander, choisissez votre taille et envoyez-nous un message sur whatsapp.</p>
                  <p>Nous livrons sur Cotonou et alentours.</p>
                </>
              )}
            </div>

            {!is_oos && (
              <OrderItemForm product={product} cart={cart} fields={['size']} onSuccess={(d) => handleSuccess(d)}>
                <Form.Submit className="w-submit btn btn-md">
                  <Icons.Whatsapp />
                  <span className="ms-1">Envoyer message</span>
                </Form.Submit>
              </OrderItemForm>
            )}

            {/* {whatsapp_number && (
            <div className="mb-3 text-center">
              <a
                href={successPath}
                className="d-block btn btn-md btn-primary px-2 fw-bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Acheter
              </a>
            </div>
          )} */}

            {product.description && <div className="p-description my-3">{product.description}</div>}

            <ul className="p-extra my-3">
              {product?.has_attributes && (
                <>
                  <li className="p-extra-title mb-1 fw-bold">Détails</li>
                  <li className="p-extra-content">
                    <ProductAttributeList attributes={product.attributes || []} />
                  </li>
                </>
              )}

              <li className="p-extra-title my-1 fw-bold">Livraison</li>
              <li className="p-extra-content">
                <p className="mb-1">
                  La livraison est gratuite sur Cotonou pour toutes commandes de 50,000 CFA et plus.
                </p>
                <div className="text-sm text-muted">
                  *Le délai de traitement de la livraison standard pour cet article est estimé à 1 à 5 jours ouvrables.
                </div>
                {/* <a href="." className="text-underline">
                  Consultez notre page Livraison
                </a> */}
              </li>
            </ul>

            {body}
          </div>
        </>

        <>{children}</>

        <>
          <ProductDetailView.HorizontalGallery />
          <ProductImagesHorizontalGallery />
        </>
      </div>
    </View>
  );
};

ProductDetailView.HorizontalGallery = ProductImagesHorizontalGallery;

export interface IProductGridViewProps extends Omit<TViewProps, 'footer'> {
  items: any[];
  viewCN?: string;
  renderItem: (i: any) => JSX.Element | null;
  pagination?: IPaginationProps;
}

export const ProductGridView = (props: IProductGridViewProps) => {
  const { viewCN, pagination, className, children, items, renderItem, ...rest } = props;

  return (
    <View {...rest} className={viewCN} footer={pagination && <Pagination {...pagination} />}>
      <ProductGridView.Grid>{children || items.map((i) => renderItem(i))}</ProductGridView.Grid>
    </View>
  );
};

ProductGridView.Grid = ({
  className = 'grid-2 grid-md-3 grid-lg-4 grid-xl-6 gap-2',
  children,
}: ProductGridProps & { children: React.ReactNode; className?: string }) => (
  <div className={getCN([className, 'miq-product-grid d-grid'])}>{children}</div>
);

export interface IProductListViewProps {}

export const ProductListView: React.FC<IProductListViewProps> = (props) => {
  return <View {...props} />;
};
