import * as React from 'react';

import { TCustomer, TCustomerInstance } from '../utils';
import Form, { TFormUpdateProps, useForm } from '@miq/formjs';
import { CustomerEmailField, CustomerFNameField, CustomerLNameField, CustomerPhoneField } from './fields';

type TCustomerFormProps = Omit<TFormUpdateProps<{ instance?: TCustomerInstance }>, 'context' | 'onSubmit'> & {
  allowSuggestions?: boolean;
  onCustomerSelect?: (p: TCustomer) => void;
  onSubmit?: (fD: { e: React.FormEvent<HTMLFormElement>; form: ReturnType<typeof useForm> }) => void;
};

export const CustomerForm = ({
  children,
  instance,
  allowSuggestions,
  onSubmit,
  onSuccess,
  onError,
  onCustomerSelect,
  ...props
}: TCustomerFormProps) => {
  const { fields } = props;
  const form = useForm<TCustomer>(getCustomerFD(instance?.export(), fields));

  const { errors } = form;
  const sharedProps = { className: 'span-md-3', fieldCN: 'd-grid grid-md-5' };

  return (
    <Form {...props} onSubmit={(e) => onSubmit?.({ e, form })} context={form} className="customer-form">
      <div className="base-fields">
        {fields?.includes('last_name') && (
          <CustomerLNameField
            allowSuggestions={allowSuggestions}
            error={errors.last_name}
            {...sharedProps}
            onCustomerSelect={onCustomerSelect}
            className=""
          />
        )}

        {fields?.includes('first_name') && <CustomerFNameField error={errors.first_name} {...sharedProps} />}

        {fields?.includes('phone') && <CustomerPhoneField error={errors.phone} {...sharedProps} />}
        {fields?.includes('email') && <CustomerEmailField error={errors.email} {...sharedProps} />}
      </div>

      {children}
    </Form>
  );
};
const getCustomerFD = (data?: TCustomer, fields?: string[]) => {
  if (!data) data = { first_name: '', last_name: '', email: '', phone: '' };
  const fd: TCustomer = {};
  fields?.forEach((field) => {
    fd[field] = data?.[field] || '';
  });

  return fd;
};

export const CustomerFields = {
  LName: CustomerLNameField,
  FName: CustomerFNameField,
  Email: CustomerEmailField,
  Phone: CustomerPhoneField,
};
