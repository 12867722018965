import { getCN } from '@miq/utiljs';
import * as React from 'react';

export type TBreadCrumb = {
  label: string;
  path: string;
  title?: string;
};

export type BreadCrumbsProps = {
  items?: TBreadCrumb[];
  className?: string;
};

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ items, ...props }) => {
  if (!items) return null;

  return (
    <section className={getCN(['miq-breadcrumbs', props.className])} role="navigation" aria-label="breadcrumbs">
      {items.map((item) => {
        return (
          <a href={item.path} className="item" key={item.label} title={item.title} aria-label={item.title}>
            {item.label}
          </a>
        );
      })}
    </section>
  );
};
