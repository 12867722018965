import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { SharedDataCtx } from '@miq/contextjs';
import { addForwardSlash, getCN } from '@miq/utiljs';
import { BaseLayout, IBaseLayoutProps, Icons, Loading } from '@miq/componentjs';

import './layout.scss';

import DashboardView from './views.Dashboard';
import { SidebarLink, TSidebarLinkProps } from './components';

const SettingLayout = React.lazy(() => import('./setting'));
const AccountStaffLayout = React.lazy(() => import('./account'));

export interface IStaffBaseLayoutProps extends Omit<IBaseLayoutProps, 'children'> {
  links: TSidebarLinkProps<{ element: React.ReactNode; mobile?: boolean }>[];
  sidebarCN?: string;
  mainCN?: string;
  mobileNavCN?: string;
}

const StaffBaseLayout: React.FC<IStaffBaseLayoutProps> = (props) => {
  const ctx = React.useContext(SharedDataCtx);

  if (!ctx) return null;

  const { className, fallback = <Loading />, ...viewProps } = props;

  if (!ctx.isLoaded) return fallback;

  const { user, site } = ctx;
  if (!user || !user.is_staff) return fallback;

  let { links, sidebarCN, mainCN, mobileNavCN, ...rest } = viewProps;

  const sidebarLinks = links.filter((i) => i.href && i);
  const mobileLinks = sidebarLinks.filter((i) => i.mobile && i);
  const routes = links.filter((i) => i.element && i);

  return (
    <BaseLayout {...rest} className={getCN([className, prefix])}>
      <nav className={getCN([`${prefix}-sidebar`, sidebarCN])}>
        <header className={`${prefix}-sidebar-header`}>
          <div className="text-md text-ellipsis p-1">{site.name}</div>
        </header>

        <section className={`${prefix}-sidebar-body`}>
          <div className={`${prefix}-sidebar-body-content`}>
            <SidebarLink href="./" label="Dashboard" icon={<Icons.Home />} />

            {sidebarLinks && sidebarLinks.map((i, v) => <SidebarLink {...i} key={v} />)}
          </div>

          <div className={`${prefix}-sidebar-body-footer`}>
            <SidebarLink href="account" label={user.first_name} icon={<Icons.UserCircle />} />
            <SidebarLink href="settings/" label="Settings" icon={<Icons.Settings />} />
          </div>
        </section>
      </nav>

      <div className={getCN([`${prefix}-main`, mainCN])} role="main">
        <div className="miq-container-fluid">
          <Routes>
            {routes.map((i, v) => (
              <Route path={`${addForwardSlash(i.href)}/*`} element={i.element} key={v} />
            ))}

            <Route path="account/*" element={<AccountStaffLayout />} />
            <Route path="settings/*" element={<SettingLayout />} />
            <Route index element={<DashboardView {...{ links }} />} />
          </Routes>
        </div>
      </div>

      <nav className={getCN([`${prefix}-mobile-nav bg-white py-1`, mobileNavCN])}>
        {mobileLinks && mobileLinks.slice(0, 3).map((i, v) => <SidebarLink {...i} key={v} />)}
        <SidebarLink href="." label="Dashboard" icon={<Icons.Home />} />
      </nav>
    </BaseLayout>
  );
};

//#region StaffLayout

export default StaffBaseLayout;

//#endregion StaffLayout

const prefix = 'miq-staff-layout';
