import * as Axios from 'axios';

import { isString, addForwardSlash } from './helpers';

import { HTTP } from './requests';

export interface IService<T extends object> {
  get<TGet = T>(path: string, config?: Axios.AxiosRequestConfig): Axios.AxiosPromise<TGet>;
  post(path: string, values: any, config?: Axios.AxiosRequestConfig): Axios.AxiosPromise<T>;
  patch(path: string, values: any, config?: Axios.AxiosRequestConfig): Axios.AxiosPromise<T>;
  put(path: string, values: any, config?: Axios.AxiosRequestConfig): Axios.AxiosPromise<T>;
  delete(path: string, config?: Axios.AxiosRequestConfig): Axios.AxiosPromise<T>;
  export(): T;
}

export class Service<T extends object> implements IService<T> {
  protected _endpoint: string;
  protected _s: Axios.AxiosInstance;

  constructor(public data: T, endpoint: string, s: Axios.AxiosInstance = HTTP) {
    this._s = s;
    this._endpoint = addForwardSlash(endpoint);
    Object.assign(this, data);
  }

  // geturl(path: string, config?: Axios.AxiosRequestConfig) {
  //   return this._s.getUri({ url: path, params: config?.params });
  // }

  get<TGet = T>(path: string, config?: Axios.AxiosRequestConfig) {
    return this._s.get<TGet>(path, config);
  }

  post(path: string, values: any, config?: Axios.AxiosRequestConfig) {
    return this._s.post<T>(path, values, config);
  }
  patch(path: string, values: any, config?: Axios.AxiosRequestConfig) {
    return this._s.patch<T>(path, values, config);
  }
  put(path: string, values: any, config?: Axios.AxiosRequestConfig) {
    return this._s.put<T>(path, values, config);
  }
  delete(path: string, config?: Axios.AxiosRequestConfig) {
    return this._s.delete<T>(path, config);
  }
  export(): T {
    return { ...this.data };
  }
}

type TResponsePromise<T> = Promise<Axios.AxiosResponse<T, any>>;
type TResponsePromiseArray<T> =
  | Promise<Axios.AxiosResponse<T, any>>
  | Promise<Axios.AxiosResponse<{ results: T[] }, any>>;

export interface ICrudService<T extends object> extends IService<T> {
  isBound: boolean;
  _slug?: string;
  _path?: string;
  create(values: any, config?: Axios.AxiosRequestConfig): TResponsePromise<T>;
  retrieve(config?: Axios.AxiosRequestConfig): TResponsePromise<T>;
  list<ResponseType = any>(config?: Axios.AxiosRequestConfig): TResponsePromiseArray<ResponseType>;
  update(values: any, config?: Axios.AxiosRequestConfig): TResponsePromise<T>;
  destroy(config?: Axios.AxiosRequestConfig): TResponsePromise<T>;
}

export class CrudService<T extends object> extends Service<T> {
  isBound: boolean = false;
  _slug?: string;
  _path?: string;

  constructor(public data: T & { slug?: string }, endpoint: string, s: Axios.AxiosInstance = HTTP) {
    super(data, endpoint, s);
    this._slug = data?.slug;
    this.isBound = isString(this?.data?.slug);

    if (this.isBound) this._path = `${this._endpoint}${this._slug}/`;
  }

  create(values: any, config?: Axios.AxiosRequestConfig) {
    return this.post(this._endpoint, values, config);
  }
  retrieve(config?: Axios.AxiosRequestConfig) {
    if (!this.isBound) throw new Error('Cannot retrieve with unbound service: Slug required');
    return this.get(this._path!, config);
  }
  list<TRList = any>(config?: Axios.AxiosRequestConfig) {
    return this.get<TRList>(this._endpoint, config);
  }
  update(values: any, config?: Axios.AxiosRequestConfig) {
    if (!this.isBound) throw new Error('Cannot update with unbound service: Slug required');
    return this.patch(this._path!, values, config);
  }
  destroy(config?: Axios.AxiosRequestConfig) {
    if (!this.isBound) throw new Error('Cannot destroy with unbound servie: Slug required');
    return this.delete(this._path!, config);
  }
}

//

// function that hashes a string
function hashString(...args: string[]): string {
  const str = args.sort().join('');
  let hash = 0;
  if (str.length === 0) return hash.toString();
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash.toString();
}

// // Add a request interceptor
// API.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     // console.log(config.url);
//     // console.log(config.params);
//     // throw new Error();
//     return config;
//   },
//   function (error) {
//     console.log(error);

//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// API.interceptors.response.use(
//   function (response) {
//     const { url, params, baseURL } = response.config;
//     console.log(response.config.params);
//     console.log(axios.getUri({ baseURL, url, params }));
//     // console.log(response);
//     // console.log(response.request.url);
//     // console.log(response.request.params);
//     // throw new Error();
//     // console.log(response);
//     return response;
//   },
//   function (error) {
//     console.log(error);
//     return Promise.reject(error);
//   }
// );
