import { DOMAIN, IS_DEV } from './constants';

export function addOrUpdateArrayObject(arr: Array<any>, obj: any, key: string = 'slug', append: Boolean = false) {
  const arrKey = arr.map((i) => i[key]);

  if (!arrKey.includes(obj[key])) {
    if (append) return [...arr, obj];
    return [obj, ...arr];
  }

  return arr.map((i) => {
    if (i[key] === obj[key]) return obj;
    return i;
  });
}

export const getWindowQuery = () => typeof window !== 'undefined' && new URL(window.location.href).searchParams;

export const getImgUrl = (src: string | null | undefined): string => {
  if (!src || !isString(src)) return '';

  if (IS_DEV && src && !src.includes('http')) {
    const domain = new URL(DOMAIN);
    return `${domain.origin}${src}`;
  }
  return src;
};

export const getCN = (df: Array<string | boolean | null | undefined> = []) => {
  if (!Array.isArray(df)) return '';

  return df
    .map((i) => i && `${i}`)
    .filter(Boolean)
    .join(' ');
};

export const addForwardSlash = (str: string) => (str.endsWith('/') ? str : `${str}/`);

export const isRequired = (name = 'Param') => {
  throw new Error(`${name} is required`);
};

export const truncateStr = (txt: string, start: number = 0, end: number = 30) =>
  txt.length > end ? `${txt.substring(start, end)} ...` : txt;
export const isString = (txt: any) => typeof txt === 'string' || txt instanceof String;

export const log = (...args: any[]) => IS_DEV && console.log(...args);
export const logInfo = (...args: any[]) => IS_DEV && console.info(...args);
export const logError = (...args: any[]) => IS_DEV && console.error(...args);
