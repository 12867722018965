import React, { useEffect, useState, createContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { HTTP, IS_DEV, logError, logInfo } from '@miq/utiljs';

type TSharedData<TExtra = any> = {
  isLoaded: boolean;
  status: 'mounted' | 'fetching' | 'fetched' | 'error';
  site?: any;
  user?: any;
  perms?: any;
} & TExtra;

export type TSDState<TExtra = any> = TSharedData<
  {
    cart?: any;
    apps?: any;
    setting?: string;
    fb_app_id?: string;
    fb_app_secret?: string;

    reload: (options?: { params?: URLSearchParams }) => Promise<TSharedData<TExtra>>;

    updateData: (data: any) => void;
    updateSite: (data: any) => void;
    updateUser: (data: any) => void;
    updateKey: (key: string, data: any) => void;
  } & TExtra
>;

const initialState: TSharedData = {
  isLoaded: false,
  site: null,
  user: null,
  perms: null,
};

export const SharedDataCtx = createContext<TSharedData | null>(initialState);

export interface SharedDataProviderProps {
  path: string;
  children: React.ReactNode;
}

export function SharedDataProvider({ children, path }: SharedDataProviderProps) {
  const [value, setValue] = useState<TSharedData>(initialState);
  const [status, setStatus] = useState<TSharedData['status']>('mounted');

  useEffect(() => {
    setStatus('fetching');
    getSharedData()
      .then((data) => {
        // logInfo('SharedData keys:', Object.keys(data));
        setValue({ ...data, isLoaded: true });
        setStatus('fetched');
      })
      .catch(() => {
        setStatus('error');
        setValue(initialState);
      });
  }, [path]);

  const sharedCtx = useMemo(() => {
    const reload = ({ params }: { params?: URLSearchParams } = {}) => {
      setStatus('fetching');
      return getSharedData({ params, reload: true })
        .then((data) => {
          setStatus('fetched');
          setValue({ ...data, isLoaded: true });
          return data;
        })
        .catch(() => {
          setStatus('error');
          setValue(initialState);
        });
    };

    const updateData = (newData: Record<string, any> = {}) => setValue({ ...value, ...newData });
    const updateKey = (key: string, data: any) => setValue({ ...value, [key]: data });

    const updateSite = (siteData: Record<string, any>) => {
      setValue({ ...value, site: { ...value.site, ...siteData } });
    };

    const updateUser = (userData: any) => {
      setValue({ ...value, user: { ...value.user, ...userData } });
    };
    return { ...value, status, reload, updateUser, updateSite, updateData, updateKey };
  }, [value, status]);

  if (!value.isLoaded) return null;

  return <SharedDataCtx.Provider value={sharedCtx}>{children}</SharedDataCtx.Provider>;
}

export const useReloadSharedData = () => {
  const mounted = React.useRef(false);
  const ctx = React.useContext(SharedDataCtx);

  const { pathname, search } = useLocation();

  const { reload } = ctx;
  React.useEffect(() => {
    if (mounted.current) {
      reload();
      // { params: new URLSearchParams(search) }
    } else {
      mounted.current = true;
    }
  }, [pathname, search]);
};

type TResponse = any;

export const getSharedData = ({
  reload,
  params,
}: { params?: URLSearchParams; reload?: boolean } = {}): Promise<TResponse> =>
  new Promise(async (resolve, reject) => {
    if (typeof window === 'undefined') return;

    let sharedData = document.getElementById('sharedData');

    if ((!sharedData && IS_DEV) || reload) {
      logInfo(`[${process.env.NODE_ENV}] Getting page shared data`);

      const base = new URL(window.location.href);
      const path = `${base.pathname}${base.search}`;

      let res;
      try {
        // logInfo(`[${process.env.NODE_ENV}] Shared data request`);
        res = await HTTP.get(path, { params, timeout: 10000 });
      } catch (err) {
        logError(`[${process.env.NODE_ENV}] Shared data error: ${err}`);
        return reject(err);
      }

      if (!res || res.status !== 200) {
        logError(`[${process.env.NODE_ENV}] Shared data response error: ${res}`);
        return reject('No data');
      }

      if (res.headers['content-type'].includes('text')) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(res.data, 'text/html');
        sharedData = doc.getElementById('sharedData');
      }
    }

    if (sharedData?.textContent) {
      const data = JSON.parse(sharedData.textContent);
      return resolve(data);
    }

    return resolve({});
  });
