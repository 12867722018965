export type TLang = Record<string, { en: string; fr: string; [key: string]: string }>;

declare global {
  interface Window {
    lang: string;
    i18n: <TLang, _Key extends keyof TLang = Extract<keyof TLang, string>>(
      file: TLang,
      key: _Key,
      params?: Record<number, string>
    ) => TLang[_Key][keyof TLang[keyof TLang]];
    // ) => string;
  }
}

if (typeof window !== 'undefined') {
  const navLang = window?.navigator?.language?.toLowerCase() || '';
  window.lang = ['en', 'en-us'].includes(navLang) ? 'en' : 'fr';
  window.i18n = (file, key, params) => {
    // let value = file[key][window.lang];
    let value = file[key][window.lang];
    if (value && params && Object.keys(params).length > 0) {
      for (let i = 0; i < Object.keys(params).length; i++) {
        value = value.replace(`{${i}}`, params[i]);
      }
    }
    return value;
  };
}

// const L: TLang = {
//   'InfoTab.title': { fr: 'Détails', en: 'Details', ig: 'Details' },
//   'InfoTab.text': { fr: 'Modifier les informations de ce produit', en: "Update product's info" },
// };
