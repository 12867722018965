import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

const CustomerCreateView = React.lazy(() => import('./views.Create'));
const CustomerUpdateView = React.lazy(() => import('./views.Update'));
const CustomerListView = React.lazy(() => import('./views.List'));

export default function CustomerRoutes() {
  return (
    <Routes>
      <Route path=":custSlug/" element={<CustomerUpdateView />} />
      <Route path="new/" element={<CustomerCreateView />} />
      <Route index element={<CustomerListView />} />
    </Routes>
  );
}

export * from './components';

export { Customer } from './utils';
export type { TCustomer, TCustomerInstance } from './utils';
export { CustomerForm, CustomerFields } from './forms';
