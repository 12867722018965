import * as React from 'react';

import './components.scss';

import { getCN } from '@miq/utiljs';
import { SharedDataCtx } from '@miq/contextjs';
import { Img, HorizontalGalleryProps, Icons } from '@miq/componentjs';

import { TProductAttribute } from '@shopy/storejs';
import { TAPIProduct } from '@shopy/salejs';

export const ProductPrice: React.FC<{
  retail_price_data: TAPIProduct['retail_price_data'];
  sale_price_data?: TAPIProduct['sale_price_data'];
  is_on_sale?: TAPIProduct['is_on_sale'];
}> = (props) => {
  const { is_on_sale, retail_price_data, sale_price_data } = props;
  const isSale = is_on_sale && sale_price_data?.amountWithSymbol != null;

  return (
    <div className={getCN(['p-price', isSale && 'sale'])}>
      {isSale && <div className="p-price-sale text-danger me-1">{sale_price_data.amountWithSymbol}</div>}
      <div className={getCN(['p-price-retail', isSale && 'text-line-through'])}>
        {retail_price_data.amountWithSymbol}
      </div>
    </div>
  );
};

export interface ProductGridProps extends React.ComponentPropsWithoutRef<'div'> {}

export const CategoryLinks = (props: { showCover?: boolean | string }) => {
  const { categories } = React.useContext(SharedDataCtx);

  if (!categories) return null;

  return (
    <div className="p-category-links pb-2">
      {categories?.map((cat) => {
        return (
          <div className="item w-25 w-md-15" key={cat.meta_slug}>
            <a href={cat.url} className="" title={cat.name}>
              {props.showCover && cat?.cover && (
                <Img.Picture
                  {...cat.cover}
                  src_mobile={cat?.cover?.thumb_sq || cat?.cover?.src_mobile}
                  style={{ width: '100%', height: 'auto', objectFit: 'cover', aspectRatio: '1/1' }}
                  className="rounded"
                />
              )}
              <div className="info">{cat.name_truncated}</div>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export type ProductImagesHorizontalGalleryProps = HorizontalGalleryProps & {};

export const ProductImagesHorizontalGallery = (props: ProductImagesHorizontalGalleryProps) => {
  return <Img.HorizontalGallery {...props} className={getCN(['p-imgs-hgallery', props.className])} />;
};

export const Video = ({ src }: { src: string }) => {
  src = 'https://cdn.accentuate.io/6648217338049/1633654669010/170T_812B_ERB_V2.mp4?v=0';

  return (
    <video
      className="intrinsic__item lazy entered loaded"
      data-src={src}
      poster=""
      autoPlay={true}
      playsInline={false}
      muted={true}
      loop={true}
      data-ll-status="loaded"
      src={src}
      width="100%"
    >
      <source data-src={src} type="video/mp4" src={src} />
    </video>
  );
};

export const ProductAttributeList = ({ attributes }: { attributes: TProductAttribute[] }) => {
  if (!attributes) return null;

  return (
    <ul className="product-attr-list">
      {attributes?.map((attr) => {
        return (
          <li className="attr" key={attr.name}>
            <span className="attr-label">{attr.name} : </span>
            <span className="attr-value">{attr.value}</span>
          </li>
        );
      })}
    </ul>
  );
};

export const PresaleStarIcon = ({ width, height }: { width?: number; height?: number }) => (
  <Icons.Star style={{ color: '#ffd740', fill: '#ffff00', strokeWidth: 1, width, height }} />
);
