import * as React from 'react';
import * as Axios from 'axios';

import Form from '@miq/formjs';
import { getCN } from '@miq/utiljs';
import { FileInput, Button, TButtonProps } from '@miq/componentjs';

import { StaffImg, TSImgInstance } from '../models';

const ACCEPT = 'image/*';
const MAX_UPLOAD_COUNT = 10;

type TImgProps<TExtra extends {} = {}> = TButtonProps<
  {
    accept?: string;
    onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSuccess?: (r?: Axios.AxiosResponse) => void;
    onError?: (r?: Axios.AxiosResponse) => void;
  } & TExtra
>;

type CreateUpdateProps = {
  multiple?: boolean;
  onSuccessMulti?: (rs: Promise<Axios.AxiosResponse>[]) => void;
  onErrorMulti?: (rs: Promise<Axios.AxiosResponse>[]) => void;
};

export type TImgButtonAddProps<TExtra extends {} = {}> = TImgProps<CreateUpdateProps & { label?: string } & TExtra>;

export const StaffImgAddButton = React.forwardRef<HTMLButtonElement, TImgButtonAddProps>(
  ({ children, ...props }, ref) => {
    let uploadRef = React.useRef<HTMLInputElement | null>(null);

    const instance = StaffImg();

    const { label, multiple, accept = ACCEPT, ...func } = props;
    const { onUpload, onSuccess, onSuccessMulti, onError, onErrorMulti, ...rest } = func;

    const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onUpload) return onUpload(e);

      const { files } = e.target;
      if (!files) return;

      const file = Array.from(files)[0];
      return instance
        .create(file)
        .then((res: any) => onSuccess?.(res))
        .catch((err: any) => onError?.(err));
    };

    const uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onUpload) return onUpload(e);

      const { files } = e.target;
      if (!files) return;

      const promises: Promise<Axios.AxiosResponse | number>[] = [];
      const errors: Promise<Axios.AxiosResponse>[] = [];

      Array.from(files)
        .splice(0, MAX_UPLOAD_COUNT)
        .forEach((file, i) => {
          promises.push(instance.create(file).catch((err: any) => errors.push(err)));
        });

      if (errors && onErrorMulti) onErrorMulti(errors);

      Promise.all(promises).then((responses: any) => {
        if (onSuccessMulti) return onSuccessMulti(responses);
      });
    };

    return (
      <>
        <Button className="miq-img-create-btn" {...rest} onClick={() => uploadRef?.current?.click()} ref={ref}>
          {children || label || `Upload image${multiple ? 's' : ''}`}
        </Button>
        <FileInput
          accept={accept}
          multiple={multiple}
          onChange={multiple ? uploadFiles : uploadFile}
          ref={(file) => (uploadRef.current = file)}
        />
      </>
    );
  }
);

export type TImgButtonUpdateProps<TExtra extends {} = {}> = TImgProps<
  CreateUpdateProps & { instance: TSImgInstance; field?: string } & TExtra
>;

export const StaffImgUpdateButton = React.forwardRef<HTMLButtonElement, TImgButtonUpdateProps>((props, ref) => {
  let uploadRef = React.useRef<HTMLInputElement | null>(null);

  const { instance, accept = ACCEPT, ...func } = props;
  let { field = 'src', onUpload, onSuccess, onError, ...rest } = func;

  if (field === 'picture') return <div className="miq-img-update-btn" children={props.children} />;

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (field === 'picture') return null;
    if (onUpload) return onUpload(e);

    const { files } = e.target;
    if (!files) return;

    const file = Array.from(files)[0];
    return instance
      .update(file, undefined, field)
      .then((res) => onSuccess?.(res))
      .catch((err) => onError?.(err));
  };

  return (
    <>
      <Button className="miq-img-update-btn" {...rest} onClick={() => uploadRef?.current?.click()} ref={ref} />
      <FileInput accept={accept} onChange={uploadFile} ref={(file) => (uploadRef.current = file)} />
    </>
  );
});

export type TImgButtonDeleteProps<TExtra extends {} = {}> = TImgProps<{ instance: TSImgInstance } & TExtra>;

export const StaffImgDeleteButton = React.forwardRef<HTMLButtonElement, TImgButtonDeleteProps>(
  ({ children, ...props }, ref) => {
    const { instance, label, ...func } = props;

    if (!instance || !instance.slug) return null;

    const { onSuccess, onError, ...rest } = func;

    const handleDelete = () =>
      instance
        .destroy()
        .then((res) => onSuccess?.(res))
        .catch((err) => onError?.(err));
    return (
      <Button
        {...rest}
        onClick={handleDelete}
        className={getCN(['miq-img-delete-btn', 'fw-bold', props.className])}
        ref={ref}
      >
        {children || label || `Delete Image`}
      </Button>
    );
  }
);

//#region EXPORTS

export const StaffImgViewSelect = React.forwardRef<HTMLInputElement, any>((props, ref) => (
  <Form.Select name="view" {...props} ref={ref}>
    <Form.Option value="src" label="Desktop" />
    <Form.Option value="mobile" label="Mobile" />
    <Form.Option value="thumb" label="Thumbnail" />
    <Form.Option value="square" label="Square" />
    <Form.Option value="picture" label="Picture" />
  </Form.Select>
));

//#endregion EXPORTS
